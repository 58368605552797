import React, { useContext, useEffect, useState } from 'react'
import Navbar from './Navbar'
import { replace, useNavigate, useParams } from 'react-router-dom'
import Backtotop from './Backtotop'
import axios from 'axios'
import { ipaddress } from '../App'
import Preloader from './Preloader'
import Upcoming_events from '../Events/Upcoming-events'
import Footer from './Footer'
import { Context } from '../Context_provider'

const Subpage = () => {

    const {event_length}=useContext(Context)

    const navigate=useNavigate()

    const user=JSON.parse(sessionStorage.getItem('user'))

    const {id}=useParams()
    const {cat_name}=useParams()
    const {subcat_name}=useParams()

    const[state,setstate]=useState(false)
    const[state2,setstate2]=useState(false)
    const[state3,setstate3]=useState(false)

    const[modules,setmodules]=useState([])

    const[module_id,setmodule_id]=useState()
    const[name,setname]=useState('')
    const[count,setcount]=useState(0)
    const[loading,setloading]=useState(false)

    useEffect(()=>{
        setloading(true)
        axios.get(`${ipaddress}/technical-skills/${id}/modules/`)
        .then((r)=>{
            // console.log("Modules",r.data);
            setmodules(r.data)
            setloading(false)
        })
    },[count])

    const[load,setload]=useState(false)

    const move=()=>{
        setload(true)
        setTimeout(() => {
            check()
        }, 1000);
    }

    const check=()=>{
        const noSpacesString = `${name}`.replace(/\s+/g, ''); 
                             const val=noSpacesString.toLowerCase()
        navigate(`/${cat_name}/${subcat_name}/${val}/coding_test/${module_id}`)
        setload(false)
    }

    const move2=()=>{
        setload(true)
        setTimeout(() => {
            check2()
        }, 1000);
    }

    const check2=()=>{
        const noSpacesString = `${name}`.replace(/\s+/g, ''); 
        const val=noSpacesString.toLowerCase()
navigate(`/${cat_name}/${subcat_name}/${val}/mcq_test/${module_id}`,{replace:true})
        setload(false)
    }

    const search_module=(value)=>{
        if(value.length>0){
            axios.get(`${ipaddress}/technical-skills/${id}/modules/?name=${value}`)
            .then((r)=>{
                // console.log("Modules",r.data);
                setmodules(r.data)
            })
        }
        else{
            setcount(count+1)
        }
    }


  return (
    <div className="row m-0 sub-page animate__animated animate__fadeIn" style={{minHeight:'100vh'}}>
        <div className={` ${event_length ? 'col-lg-9':'col-12'}`}>
        <div>
        <Navbar value={"home"}/>
        {loading ? (<Preloader/>):(
            <div className="">
            <div className='pb-5 pt-4 container'>
                
                <div className='row m-0 p-4 mx-md-2 rounded align-items-center subpage-subdiv'>
                    <div className="col-md-10">
                    <p className='m-0 fs-3 fw-medium text-white'>Get on Job Training</p>
                    <p className='m-0 fs-3 fw-medium text-white'>& Hands on job experience</p>

                    <button className='btn text-white rounded-pill px-3 mt-4 py-2' style={{backgroundColor:'#202020',fontSize:'14px'}}>Explore Jobs
                        <img src={require('../images/subCategories/btn.png')} className='ms-3' alt="" />
                    </button>
                    </div>
                    <div className="col-md-2 d-flex justify-content-end">
                        <img src={require('../images/subCategories/fi_3688609.png')} style={{width:'90%'}} alt="" />
                    </div>
                </div>
    
                <div className="row m-0 mt-5">
                <div className="col-lg-7 mx-auto px-md-0">
                    <div class="input-group mb-3 rounded border border-dark py-2 rounded-pill mb-4">
      <span class="input-group-text border-0 bg-transparent ps-3" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
    </svg></span>
      <input type="text" onChange={(e)=>{
        search_module(e.target.value)
      }} class="form-control border-0 bg-transparent shadow-none" placeholder="Search Modules..." aria-label="Username" aria-describedby="basic-addon1"/>
    </div>
                    </div>
                </div>
                <div className='row m-0 mt-3'>
                {modules.map((x)=>{
                    return(
                        <div className="col-md-6 col-lg-3 mb-4 mt-2 px-2 px-md-4" style={{cursor:'pointer'}} onClick={()=>{
                            setmodule_id(x.module_id)
                            setname(x.name)
                            setstate(true)
                        }}>
                            <div className='sublang-div row m-0 rounded py-3' style={{height:'220px'}}>
                                <div className='col-12'>
                               <div className='text-center' style={{height:'60%'}}>
                               <img src={x.image} style={{width:'40%'}} alt="" />
                               </div>
                                    <p className='fw-medium m-0 text-white text-center mt-3' style={{fontSize:'22px'}}>{x.name.toUpperCase()}</p>
                                   </div>
                            </div>
                        </div>
                    )
                })}
                </div>
            </div>
            </div>
        )}

{/* Layout */}
<div className={state ? 'animate__animated animate__fadeIn':'d-none'} style={{backgroundColor:'rgba(0,0,0,0.6)',width:'100%',height:'100vh',position:'fixed',top:0,left:0,zIndex:6}}>
<div className="row h-100 align-items-center justify-content-center">
    <div className="bg-white col-md-4 p-2" style={{borderRadius:'15px'}}>
        <p className='text-end m-0 p-0'><svg onClick={()=>{
            setstate(false)
        }} style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#0D6EFD" class="bi bi-x-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg></p>

<img src={require('../images/002_illustration.png')} className='d-block mx-auto' style={{width:'50%'}} alt="" />
        <div className='d-flex pt-4 pb-2 mb-2 align-items-center justify-content-center'>
            <button className={`btn btn-primary px-5 mt-4 mx-2 py-2 ${module_id===46 || module_id===51 || module_id===48 || module_id===45 ? 'd-none':''}`} style={{width:'200px',fontSize:'14px',borderRadius:'10px'}} onClick={()=>{
                setstate3(true)
            }}>MCQ Test</button>
             <button className={`btn btn-primary px-5 mt-4 mx-2 py-2 ${(module_id===48 && subcat_name==='humanresource') || (module_id===45 && subcat_name==='subdigitalmarketing') ? '':'d-none'}`} style={{width:'200px',fontSize:'14px',borderRadius:'10px'}} onClick={()=>{
                setstate3(true)
            }}>True/False</button>

            <button className={`btn btn-primary px-5 mt-4 mx-2 py-2 ${module_id===42 || module_id===45 || module_id===46 || module_id===51 || module_id===35 || module_id===38 || module_id===48 || module_id===47 ? 'd-none':''}`} style={{width:'200px',fontSize:'14px',borderRadius:'10px'}} onClick={()=>{
                setstate2(true)
            }}>Coding Test</button>
             <button className={`btn btn-primary px-5 mt-4 mx-2 py-2 ${(module_id===46 && subcat_name==='subdigitalmarketing') || (module_id===51 && subcat_name==='humanresource') ? '':'d-none'}`} style={{width:'200px',fontSize:'14px',borderRadius:'10px'}} onClick={()=>{
                setstate2(true)
            }}>Short Answer</button>
        </div>
    </div>
</div>
</div>

{/* Information Layout */}
<div className={state2 ? 'animate__animated animate__fadeIn':'d-none'} style={{backgroundColor:'rgba(0,0,0,0.6)',width:'100%',height:'100%',position:'fixed',top:0,left:0,zIndex:6}}>
<div className="row h-100 align-items-center justify-content-center">
    <div className="bg-white col-md-4 p-4 my-4" style={{borderRadius:'10px'}}>
        <img src={require('../images/image 16.png')} className='d-block mx-auto' alt="" />
        <div className='py-2'>
           <div>
            <p className='fs-5 text-center m-0' style={{fontWeight:600}}>Read the Instructions Below !</p>
            <p className='text-secondary text-center' style={{fontSize:'14px'}}>All the best</p>
            <p></p>

            <div className='mt-4' style={{height:'40vh',overflowY:'scroll'}}>
                <div className='instruction-para px-2'><img src={require('../images/Featured icon.png')} alt="" /> <span className='ms-2'>For each question once you complete the coding you have to click on Save button.</span></div>
                <div className='instruction-para px-2'><img src={require('../images/Featured icon.png')} alt="" /> <span className='ms-2'>Clik on Next button to submit the particular question.</span></div>
                <div className='instruction-para px-2'><img src={require('../images/Featured icon.png')} alt="" /> <span className='ms-2'>Once you attended all the questions, then click on Submit Test button to submit the test.</span></div>
                <div className='instruction-para px-2'><img src={require('../images/Featured icon.png')} alt="" /> <span className='ms-2'>While attending test if you navigate to other window, then test will get submit automatically.</span></div>
                <div className='instruction-para px-2'><img src={require('../images/Featured icon.png')} alt="" /> <span className='ms-2'>While attending test if you refresh the page, then test will get submit automatically.</span></div>

                {/* <p className='text-success fw-bold text-center'>All The Best!!!</p> */}        
            
            </div>
            <div className='pt-4 row m-0'>
               <div className="col-md-6">
               <button style={{height:'42px',fontSize:'14px',borderRadius:'10px'}} className='w-100 btn border-0 btn-light fw-medium px-4' onClick={()=>{
                setstate2(false)
            }}>Cancel</button>
               </div>
              <div className="col-md-6">
              <button style={{height:'42px',fontSize:'14px',borderRadius:'10px'}} className='w-100 btn btn-primary px-4 py-2' onClick={()=>{
                move()
            }}>
                 <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
                 <span className={`${load ? 'ms-2':'d-none'}`} role="status">Proceeding...</span>
                 <span className={`${load ? 'd-none':''}`}>Proceed to Test</span>
            </button>
              </div>
                </div>
           </div>
        </div>
    </div>
</div>
</div>

{/* Information2 Layout */}
<div className={state3 ? 'animate__animated animate__fadeIn':'d-none'} style={{backgroundColor:'rgba(0,0,0,0.6)',width:'100%',height:'100vh',position:'fixed',top:0,left:0,zIndex:6}}>
<div className="row h-100 align-items-center justify-content-center">
    <div className="bg-white col-md-4 p-4" style={{borderRadius:'10px'}}>
        <img src={require('../images/image 16.png')} className='d-block mx-auto' alt="" />
        <div className='py-2'>
           <div>
            <p className='fs-5 text-center m-0' style={{fontWeight:600}}>Read the Instructions Below !</p>
            <p className='text-secondary text-center' style={{fontSize:'14px'}}>All the best</p>
            <p></p>

            <div className='mt-4' style={{height:'40vh',overflowY:'scroll'}}>
                <div className='instruction-para px-2'><img src={require('../images/Featured icon.png')} alt="" /> <span className='ms-2'>For each question you have to select the option.</span></div>
                <div className='instruction-para px-2'><img src={require('../images/Featured icon.png')} alt="" /> <span className='ms-2'>In right side tab you can select the question by clicking on question number.</span></div>
                <div className='instruction-para px-2'><img src={require('../images/Featured icon.png')} alt="" /> <span className='ms-2'>Once you attended all the questions, then click on Submit Test button to submit the test.</span></div>
                <div className='instruction-para px-2'><img src={require('../images/Featured icon.png')} alt="" /> <span className='ms-2'>While attending test if you navigate to other window, then test will get submit automatically.</span></div>
                <div className='instruction-para px-2'><img src={require('../images/Featured icon.png')} alt="" /> <span className='ms-2'>While attending test if you refresh the page, then test will get submit automatically.</span></div>

                {/* <p className='text-success fw-bold text-center'>All The Best!!!</p> */}    
            </div>
            <div className='pt-4 row m-0'>
                <div className="col-md-6">
                <button style={{height:'42px',fontSize:'14px',borderRadius:'10px',backgroundColor:'#F8F9FA'}} className='w-100 btn border-0 fw-medium px-4' onClick={()=>{
                setstate3(false)
            }}>Cancel</button>
                </div>
               <div className="col-md-6">
               <button style={{height:'42px',fontSize:'14px',borderRadius:'10px'}} className='w-100 btn btn-primary px-4 py-2' onClick={()=>{
                move2()
            }}>
                 <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
                 <span className={`${load ? 'ms-2':'d-none'}`} role="status">Proceeding...</span>
                 <span className={`${load ? 'd-none':''}`}>Proceed to Test</span>
            </button>
               </div>
                </div>
           </div>
        </div>
    </div>
</div>
</div>
    </div>
        </div>

        <div className={`col-lg-3 ${event_length ? '':'d-none'}`}>
        <Upcoming_events/>
    </div>

        <Footer/>
        {/* <Backtotop/> */}
    </div>
  )
}

export default Subpage