import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress } from '../App'
import { useNavigate } from 'react-router-dom'
import Backtotop from '../Pages/Backtotop'

const View_technical_akills = () => {
    const[skills,setskills]=useState([])

    useEffect(()=>{
        axios.get(`${ipaddress}/technical-skills/`)
        .then((r)=>{
            console.log("skills",r.data);
            setskills(r.data)
        })
    },[])

    const navigate=useNavigate()


  return (
    <div className='d-flex'>
      <Sidebar activevalue={"skills"}/>
      <div className="w-100 animate__animated animate__fadeIn">
        <Navbar/>
        <div className="main-container p-4">
            <h5 className='mb-4'>Technical Skills</h5>

            <div className="table-responsive">
            <table class="table shadow-sm">
  <thead>
    <tr>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>SI.No</p></th>
      <th scope="col" className='border-0'></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Skill Name</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Description</p></th>
    </tr>
  </thead>
  <tbody>
    {skills.map((x,index)=>{
        return(
            <tr className={index%2==0 ? 'table-light':''}>
      <th scope="row" className='border-0'><p className='text-center m-0 py-2'>{index+1}</p></th>
      <td className='border-0'><div className='d-flex align-items-center justify-content-center'>
        <img src={x.image} className='rounded-circle' width={40} height={40} alt="" /></div></td>
      <td className='border-0'><p className='text-center m-0 text-primary fw-medium  py-2' style={{cursor:'pointer'}} onClick={()=>{
        navigate(`/view_modules/${x.tech_id}`)
      }}>{x.name}</p></td>
      <td className='border-0'><p className='text-center m-0 py-2'>{x.desc}</p></td>
    </tr>
        )
    })}
  </tbody>
</table>
            </div>
        </div>
      </div>

      <Backtotop/>
    </div>
  )
}

export default View_technical_akills
