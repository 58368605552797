import React from 'react'
import Main_navbar from './Main_navbar'
import { useNavigate } from 'react-router-dom'
import Main_footer from './Main_footer'
import Backtotop from './Backtotop'
import Homepage2 from './Homepage2'

const Homepage = () => {
    const navigate=useNavigate()

  return (
    <div className='animate__animated animate__fadeIn'>
        <Main_navbar value={"home"}/>

        <div className="main-container2" style={{minHeight:'100vh'}}>

            {/* Conatiner 1 */}
            <div className="m-0 row align-items-center container mx-auto" style={{height:'90vh'}}>
                <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <div className='pe-4'>
                    <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Master Your Skills Grow</p>
                    <p className='fw-medium' style={{letterSpacing:'1px',fontSize:'35px'}}>with Expert Guidance</p>
                    <p className='text-secondary'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    
                    <div className='mt-4'>
                        <button onClick={()=>{
                            navigate('/signuppage')
                        }} className='btn text-white px-5 py-2 fw-medium' style={{backgroundColor:'#185CFF',fontSize:'14px'}}>Sign Up</button>
                        <button className='btn text-dark px-5 py-2 fw-medium' onClick={()=>{
                            navigate('/loginpage')
                        }} style={{fontSize:'14px'}}>Login Now</button>
                    </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <img src={require('../images/001_illustration.png')} style={{width:'100%'}} alt="" />
                </div>
            </div>

            {/* Container 2 */}
            <Homepage2/>


        {/* Container 3 */}
        <div className="m-0 row align-items-center container mx-auto py-5">
                <div className="col-md-6 mb-4 d-flex align-items-center justify-content-center">
                    <div className='pe-4'>
                    <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Discover Your Learning</p>
                    <p className='fw-medium' style={{letterSpacing:'1px',fontSize:'35px'}}>Journey</p>
                    <p className='text-secondary'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    
                    <div className='mt-4'>
                        <button className='btn text-white px-5 py-2 fw-medium' style={{backgroundColor:'#185CFF',fontSize:'14px'}}>Learn more</button>
                       
                    </div>
                    </div>
                </div>
                <div className="col-md-6 mb-4">
                    <img src={require('../images/002_illustration.png')} style={{width:'100%'}} alt="" />
                </div>

                <div className="col-md-6 mt-4">
                    <img src={require('../images/003_llustration.png')} style={{width:'100%'}} alt="" />
                </div>
                <div className="col-md-6 mt-4 d-flex align-items-center justify-content-center">
                    <div className='ps-4'>
                    <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Navigate Our</p>
                    <p className='fw-medium' style={{letterSpacing:'1px',fontSize:'35px'}}>Assessment Process</p>
                    <p className='text-secondary'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    
                    <div className='mt-4'>
                        <button className='btn text-white px-5 py-2 fw-medium' style={{backgroundColor:'#185CFF',fontSize:'14px'}}>Learn more</button>
                       
                    </div>
                    </div>
                </div>
        </div>

         {/* Container 4 */}
         <div className='py-5' style={{backgroundColor:'#F1F6FA'}}>
            <div className="container">
            <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Trusted by Students for</p>
            <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Excellence in Learning</p>
            </div>
            
            <div className="row m-0 mt-5 container mx-auto">
                <div className="col-md-6 p-4">
                    <div className='bg-white rounded p-4 shadow' style={{height:'300px',position:'relative'}}>
                        <img src={require('../images/001_img_person_1.png')} style={{position:'absolute',top:'-40px'}} alt="" />
                        <p style={{color:'#9497A1',height:'140px'}} className='mt-5'>“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum.” </p>
                    
                    <div className=''>
                        <p className='fw-medium m-0' style={{fontSize:'15px'}}>Alex Bern</p>
                        <p className='text-seconadry m-0' style={{fontSize:'13px'}}>CEO by ghfghfgh</p>
                    </div>
                    </div>
                </div>

                <div className="col-md-6 p-4">
                    <div className='bg-white rounded p-4 shadow' style={{height:'300px',position:'relative'}}>
                        <img src={require('../images/001_img_person_1.png')} style={{position:'absolute',top:'-40px'}} alt="" />
                        <p style={{color:'#9497A1',height:'140px'}} className='mt-5'>“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum.” </p>
                    
                    <div className=''>
                        <p className='fw-medium m-0' style={{fontSize:'15px'}}>Alex Bern</p>
                        <p className='text-seconadry m-0' style={{fontSize:'13px'}}>CEO by ghfghfgh</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>

          {/* Container 5 */}
          <div className='py-5' style={{backgroundColor:'#ffff'}}>
            <p className='fw-medium m-0 text-center container mx-auto' style={{letterSpacing:'1px',fontSize:'35px'}}>Blog</p>
            <p className='mt-3 text-secondary text-center container mx-auto px-2 px-md-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>

            <div className="row m-0 mt-4 container mx-auto">
                <div className="col-md-4 p-4">
                <div class="card p-3">
  <img src={require('../images/Rectangle 14.png')} class="card-img-top" alt="..."/>
  <div class="card-body p-0 pt-3">
    <h6 class="card-title">6 Strategies to Find Your Conference Keynote and Other Speakers</h6>
    <p class="card-text mt-4" style={{fontSize:'14px'}}>Sekarang, kamu bisa produksi tiket fisik untuk eventmu bersama Bostiketbos. Hanya perlu mengikuti beberapa langkah mudah.</p>
    <p className='m-0' style={{fontSize:'13px',color:'#A5A5A5'}}>12 Mar - John Doe</p>
  </div>
</div>
                </div>

                <div className="col-md-4 p-4">
                <div class="card p-3">
  <img src={require('../images/Rectangle 14.png')} class="card-img-top" alt="..."/>
  <div class="card-body p-0 pt-3">
    <h6 class="card-title">Introducing Workspaces: Work smarter, not harder with new navigation</h6>
    <p class="card-text mt-4" style={{fontSize:'14px'}}>Sekarang, kamu bisa produksi tiket fisik untuk eventmu bersama Bostiketbos. Hanya perlu mengikuti beberapa langkah mudah.</p>
    <p className='m-0' style={{fontSize:'13px',color:'#A5A5A5'}}>12 Mar - John Doe</p>
  </div>
</div>
                </div>

                <div className="col-md-4 p-4">
                <div class="card p-3">
  <img src={require('../images/Rectangle 14.png')} class="card-img-top" alt="..."/>
  <div class="card-body p-0 pt-3">
    <h6 class="card-title">How Successfully Used Paid Marketing to Drive Incremental Ticket Sales</h6>
    <p class="card-text mt-4" style={{fontSize:'14px'}}>Sekarang, kamu bisa produksi tiket fisik untuk eventmu bersama Bostiketbos. Hanya perlu mengikuti beberapa langkah mudah.</p>
    <p className='m-0' style={{fontSize:'13px',color:'#A5A5A5'}}>12 Mar - John Doe</p>
  </div>
</div>
                </div>
            </div>

            <div className='text-center mt-2'>
                <button className='btn rounded-pill px-4 py-2 fw-medium' style={{border:'1px solid #3D37F1',color:'#3D37F1'}}>Load More</button>
            </div>
        </div>

        {/* Container 6 */}
        <div className='' style={{backgroundColor:'#185CFF',minHeight:'200px'}}>
            <div className="row container m-0 mx-auto h-100 align-items-center">
                <div className="col-md-3 border-end px-4">
                    <p className='fw-bolder fs-2 text-white text-center m-0'>1830+</p>
                    <p className='text-white fw-medium text-center mt-1'>Questions Listed
                    in the portal</p>
                </div>
                <div className="col-md-3 border-end px-4">
                    <p className='fw-bolder fs-2 text-white text-center m-0'>220</p>
                    <p className='text-white fw-medium text-center mt-1'>Student Enrolled
                    this month</p>
                </div>
                <div className="col-md-3 border-end px-4">
                    <p className='fw-bolder fs-2 text-white text-center m-0'>390h</p>
                    <p className='text-white fw-medium text-center mt-1'>of time spends by
                    Student</p>
                </div>
                <div className="col-md-3 px-4">
                    <p className='fw-bolder fs-2 text-white text-center m-0'>834+</p>
                    <p className='text-white fw-medium text-center mt-1'>Satisfied google
                    Review</p>
                </div>
            </div>
        </div>

        {/* Container 7 */}
        <div style={{backgroundColor:'#F1F6FA',paddingTop:'70px',paddingBottom:'70px'}} className=''>
            <div className="row container m-0 mx-auto">
                <div className="col-md-6 d-flex flex-column justify-content-between">
                    <div>
                    <p className='fw-bold fs-3 m-0'>Left questions? Contacts us</p>
                    <p className='fw-bold fs-3'>now for a consultation</p>
                    <p className='text-secondary' style={{fontSize:'14px'}}>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi.</p>
                    </div>
                
                <div className='mt-5'>
                    <p className='m-0 text-secondary' style={{fontSize:'14px'}}>Email address</p>
                    <p className='fw-medium mt-1' style={{fontSize:'14px'}}>madhvaan1312000@gmail.com</p>

                    <p className='m-0 text-secondary' style={{fontSize:'14px'}}>Phone Number</p>
                    <p className='fw-medium mt-1' style={{fontSize:'14px'}}>8989898989</p>

                    <p className='m-0 text-secondary' style={{fontSize:'14px'}}>Address</p>
                    <p className='fw-medium mt-1' style={{fontSize:'14px'}}>Jayanagar,Bangalore.</p>
                </div>
                </div>

                <div className="col-md-6">
                    <div className='bg-white rounded shadow p-3 p-md-5 mx-auto home-form'>
                        <p className='fw-bold fs-5'>Contact Us</p>

                        <div className='mt-4'>
                            <label htmlFor="" className='text-secondary' style={{fontSize:'14px'}}>Name</label>
                            <div className='border-bottom border-secondary'>
                                <input type="text" className='form-control border-0 shadow-none outline-0 bg-transparent' name="" id="" />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <label htmlFor="" className='text-secondary' style={{fontSize:'14px'}}>Email</label>
                            <div className='border-bottom border-secondary'>
                                <input type="text" className='form-control border-0 shadow-none outline-0 bg-transparent' name="" id="" />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <label htmlFor="" className='text-secondary' style={{fontSize:'14px'}}>Theme</label>
                            <div className='border-bottom border-secondary'>
                                <input type="text" className='form-control border-0 shadow-none outline-0 bg-transparent' name="" id="" />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <label htmlFor="" className='text-secondary' style={{fontSize:'14px'}}>Message</label>
                            <div className='border-bottom border-secondary'>
                                <input type="text" className='form-control border-0 shadow-none outline-0 bg-transparent' name="" id="" />
                            </div>
                        </div>

                        <div className='mt-4 pt-2 text-end'>
                            <button className='btn text-white px-5 py-2' style={{backgroundColor:'#185CFF',fontSize:'13px'}}>Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Conatiner 8 */}
        <div className=''>
            <img src={require('../images/image 1.png')} style={{width:'100%'}} alt="" />
        </div>
        </div>

        <Main_footer/>

        <Backtotop/>
    </div>
  )
}

export default Homepage