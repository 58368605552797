import React from 'react'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'

const Success = ({score,setscore}) => {

  console.log(score)
    const user=JSON.parse(sessionStorage.getItem('user'))

    const navigate=useNavigate()

  return (
    <div>
        <div className='container'>
        <div className={`success pt-5 animate__animated animate__bounceIn`}>
          <div className='text-center'>
            <img src={require('../images/Group 19.png')} style={{width:'40%'}} alt="" />
          </div>
          <p className='text-info fw-medium fs-4 text-center mt-4 m-0'>
            Congratulations <span className='text-dark ms-1'>{user.username}!</span>
          </p>
          <p className='text-center mt-2 fw-medium'>Your test got submitted!</p>
         {score!==null && (
           <p className='text-center mt-2 fw-medium'>Your score is <span className='text-primary fw-medium'>{score && score.score} / {score && score.total_marks}</span></p>
         )}
          <div className='text-center'>
            <button className='btn text-white rounded-pill px-4' onClick={()=>{
              setscore(null)
                navigate('/all-tests')
            }} style={{ fontSize: '13px', backgroundColor: '#185CFF'}}>Back to categories</button>
          </div>
        </div>
            </div>
    </div>
  )
}

export default Success