import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress } from '../App'
import { useNavigate } from 'react-router-dom'
import Backtotop from '../Pages/Backtotop'
import FieldSelector from './Filter'

const Students = () => {

  const[show,setshow]=useState(false)

    const[students,setstudents]=useState([])

    useEffect(()=>{
       fetch_students()
    },[])

    const fetch_students=()=>{
        axios.get(`${ipaddress}/student/details/`)
        .then((r)=>{
            console.log("students",r.data);
            const date=new Date()
            console.log(date)
            setstudents(r.data)
        })
    }

    const navigate=useNavigate()

    const search_student=(value)=>{
        if(value.length>0){
            axios.get(`${ipaddress}/student/details/?student_name=${value}`)
            .then((r)=>{
                console.log("Searched students",r.data);
                setstudents(r.data)
            })
        }
        else{
            fetch_students()
        }
    }

    const download_data=()=>{

      fetch(`${ipaddress}/ExportTestResultsToExcelView/`)  // Replace with your backend API URL
    .then(response => response.json())
    .then(data => {
        const excelBase64 = data.excel_data;  // Base64 string from backend
        const filename = data.filename;       // Filename from backend

        // Decode base64 data
        const binaryString = window.atob(excelBase64);

        // Convert binary string to array buffer
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a blob from the array buffer (Excel MIME type)
        const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link element for downloading
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);  // Create object URL from blob
        link.href = url;
        link.download = filename;               // Set the filename from the response
        link.click();

        // Clean up URL object
        URL.revokeObjectURL(url);
    })
    .catch(error => {
        console.error('Error:', error);
    });
    }

  return (
    <div className='d-flex'>
      <Sidebar activevalue={"students"}/>
      <div className="w-100 animate__animated animate__fadeIn">
        <Navbar/>
        <div className="main-container py-4 pe-md-4 ps-md-2">
            <div className='d-flex mb-4 align-items-center justify-content-between'>
            <h5 className=''>Students List ( <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="gray" class="bi bi-people-fill" viewBox="0 0 16 16">
  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
</svg> <span style={{fontSize:'15px',color:'gray'}}>{students.length} Students</span> )</h5>
           <div className='d-flex align-items-center'>
           <button onClick={()=>fetch_students()} className='btn btn-sm btn-primary px-4 py-1'>All</button>
            <button onClick={()=>setshow(true)} className='btn btn-sm btn-primary px-4 py-1 ms-3'>Filter</button>
           <div class="input-group ms-3">
  <input type="text" onChange={(e)=>{
    search_student(e.target.value)
  }} class="form-control shadow-none border-secondary-subtle" placeholder="Search..." aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <span class="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg></span>
</div>
           </div>
            </div>
            <div className="table-responsive">
              <button className='btn btn-success px-4 d-block ms-auto mb-3' onClick={download_data}>Download</button>
            <table class="table shadow-sm">
  <thead>
    <tr>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>SI.No</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Name</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Email</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Contact Number</p></th>
    </tr>
  </thead>
  <tbody>
    {students.map((x,index)=>{
        return(
            <tr className={index%2==0 ? 'table-light':''}>
      <th scope="row" className='border-0'><p className='text-center m-0 py-2'>{index+1}</p></th>
      <td className='border-0'><p className='text-center m-0 text-primary fw-medium  py-2' style={{cursor:'pointer'}} onClick={()=>{
        navigate(`/view_student/${x.id}`)
      }}>{x.username}</p></td>
      <td className='border-0'><p className='text-center m-0 py-2'>{x.email}</p></td>
      <td className='border-0'><p className='text-center m-0 py-2'>{x.contact}</p></td>
    </tr>
        )
    })}
  </tbody>
</table>
            </div>
        </div>
      </div>

      <FieldSelector show={show} setshow={setshow} settutors={setstudents}/>

      <Backtotop/>
    </div>
  )
}

export default Students
