import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress, ipaddress2 } from '../App'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Backtotop from '../Pages/Backtotop'

const Vew_attended_questions = () => {
    const[state,setstate]=useState('mcq')

    const {id}=useParams()

    const downloadCertificate = () => {
      const input = document.getElementById('mcqs');
      html2canvas(input, { scale: 3, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('landscape', 'pt', [canvas.width, canvas.height]); // Set PDF dimensions based on the canvas
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0; // Align to the left
        const imgY = 0; // Align to the top
  
        pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save('answers.pdf');
      }).catch(error => {
        console.error('Error generating PDF:', error)
      })
    }

    const navigate=useNavigate()

    const[mcq_questions,setmcq_questions]=useState([])
    const[coding_questions,setcoding_questions]=useState([])

    const user=JSON.parse(sessionStorage.getItem('user'))

    useEffect(()=>{
        axios.get(`${ipaddress}/tests/${id}/questions/`)
        .then((r)=>{
            console.log("Attended MCQ Questions",r.data)
            setmcq_questions(r.data)
        })
        // fetchcoding_questions()
    },[])

    const fetchcoding_questions=()=>{
      axios.get(`${ipaddress}/module-coding-questions/?module_id=${id}`)
      .then((r)=>{
          console.log("All coding question paapers",r.data)
          setcoding_questions(r.data)
      })
    }


  return (
    <div className='d-flex'>
        <Sidebar activevalue={"students"}/>
        <div className="w-100 animate__animated animate__fadeIn">
            <Navbar/>
            <div className="main-container py-3 pe-md-4 ps-md-2" style={{minHeight:'100vh'}}>
            <div className="d-flex pb-4 align-items-center justify-content-between">
            <h6 className='fs-5 m-0'>
                {/* <svg style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/view_modules/${id}`)
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg>  */}
<span className='ms-2'>Attended MCQ Questions</span></h6>

<button className='btn btn-danger px-3' onClick={downloadCertificate}>Download PDF</button>
            </div>
            <div className='d-flex gap-2 gap-md-5 pb-2'>
              <h5 onClick={()=>{
                setstate('mcq')
              }} className='py-2 px-4 d-flex align-items-center pb-3 fs-6' style={{color:state==='mcq' ? '#57309C':'',cursor:'pointer',borderBottom:state==='mcq' ? '2px solid #57309C':'none'}}>
  <span className=''>MCQ Questions ({mcq_questions.length})</span></h5>
            </div>

{/* MCQ Questions */}
<div id='mcqs' className={`${state==='mcq' ? '':'d-none'}`}>
    <h6 className={`text-center py-4 ${mcq_questions.length>0 ? 'd-none':''}`} style={{cursor:'pointer'}} onClick={()=>{
        navigate('/assign_test')
    }}>Create Question Paper...✍</h6>

<div className={`${mcq_questions.length>0 ? '':''} row m-0`}>
  {mcq_questions.map((x,index)=>{
    const data=x.question.options.filter(a=>a.id===x.selected_option)
    // console.log("Filtered option",data);
    
    return(
      <div className="col-12 px-2 mt-4">
  <div className='bg-white rounded shadow-sm p-4'>
    <p className='fw-medium text-primary' style={{fontSize:'17px'}}>{index+1}] <span className='fw-medium' style={{fontSize:'17px'}}>{x.question && x.question.question_text}</span></p>
    {x.question.imageforquestion && (
                            <img src={x.question.imageforquestion} style={{width:'40%'}} alt="" />
                        )}

    {x.question && x.question.options && x.question.options.map((y,index2)=>{
      return(
        <div>
          <p className=''>{index2+1}] <span className={` ${y.is_correct ? 'bg-success-subtle p-1 rounded px-3':''} ${x.selected_option===y.id && y.is_correct ? 'text-success fw-bold ': x.selected_option===y.id && y.is_correct==false ? 'text-danger fw-bold ':''}`}>{y.option_text}</span></p>
          {y.imageforoption && (
                            <img src={y.imageforoption} style={{width:'30%'}} alt="" />
                        )}
          {/* <p className='mt-2 text-secondary'><span className='fw-medium text-decoration-underline ms-3' style={{fontSize:'14px'}}>Description : </span><span className='ms-2' style={{fontSize:'13px'}}>{y.description}kjhjjhj</span></p> */}
        </div>
      )
    })}

<div className='d-flex align-items-center justify-content-end'>
      <span className='fw-medium'>Marks Scored : </span>
    <span className={data[0] && data[0].is_correct ? 'text-success fw-bold ms-2':'d-none'}>{x.question && x.question.marks}</span>
    <span className={data[0] && data[0].is_correct==false ? 'text-danger fw-bold ms-2':'d-none'}>{x.question && x.question.negative_marks}</span>
    </div>
  </div>
</div>
    )
  })}

</div>

{/* 
<div className={`table-responsive ${mcq_questions.length>0 ? '':'d-none'}`}>
                <table class="table">
  <thead>
    <tr>
      <th scope="col" className='py-3'><p className='text-center m-0'>SI No</p></th>
      <th scope="col" className='py-3'><p className='text-center m-0'>Title</p></th>
      <th scope="col" className='py-3'><p className='text-center m-0'>Created On</p></th>
      <th scope="col" className='py-3'></th>
    </tr>
  </thead>
  <tbody>
    {mcq_questions.map((x,index)=>{
        return(
            <tr>
      <td scope="row" className=''><p className='text-center m-0'>{index+1}</p></td>
      <td className=''><p className='text-center m-0'>{x.title}</p></td>
      <td className=''><p className='text-center m-0'>{x.created_at && x.created_at.slice(0,10)}</p></td>
      <td className=''><div className='d-flex justify-content-center'>
        <button className='btn btn-sm btn-primary' onClick={()=>{
            navigate(`/mcq_question/${x.id}`)
        }}>View Question</button>
        </div></td>
    </tr>
        )
    })}
  </tbody>
</table>
                </div> */}
</div>



{/* Coding Questions */}
<div className={`${state==='coding' ? '':'d-none'}`}>
    <h6 className={`text-center py-4 ${coding_questions.length>0 ? 'd-none':''}`} style={{cursor:'pointer'}} onClick={()=>{
        navigate('/assign_test')
    }}>Create Question Paper...✍</h6>

<div className={`${coding_questions.length>0 ? '':'d-none'} row m-0`}>
  {coding_questions.map((x,index)=>{
    return(
      <div className="col-12 px-2 mt-4">
  <div className='bg-white rounded shadow-sm p-4'>
    <p className='fw-medium text-primary' style={{fontSize:'17px'}}>{index+1}] <span className='fw-medium' style={{fontSize:'17px'}}>{x.question_text}</span></p>

    {x.sample_input && (<p className='fw-bold' style={{fontSize:'15px'}}>Sample Input  : <span className='fw-normal' style={{fontSize:'15px'}}>{x.sample_input}</span></p>)}
    {x.sample_output && (<p className='fw-bold' style={{fontSize:'15px'}}>Sample Output : <span className='fw-normal' style={{fontSize:'15px'}}>{x.sample_output}</span></p>)}
    {x.constraints && (<p className='fw-bold' style={{fontSize:'15px'}}>Constraints   : <span className='fw-normal' style={{fontSize:'15px'}}>{x.constraints}</span></p>)}
    {x.explanation && (<p className='fw-bold' style={{fontSize:'15px'}}>Explanation : <span className='fw-normal' style={{fontSize:'15px'}}>{x.explanation}</span></p>)}
    
  </div>
</div>
    )
  })}
</div>
</div>
            </div>
        </div>

    <Backtotop/>
    </div>
  )
}

export default Vew_attended_questions