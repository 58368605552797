import React from 'react'

const Svg1 = () => {
  return (
    <div>
      <svg width="21" height="21" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M256 15.0586C180.706 15.0586 120.47 75.2939 120.47 150.588V210.823C94.8704 210.823 75.2939 230.4 75.2939 256V451.764C75.2939 477.364 94.8704 496.941 120.47 496.941H391.529C417.129 496.941 436.706 477.364 436.706 451.764V256C436.706 230.4 417.129 210.823 391.529 210.823V150.588C391.529 75.2939 331.294 15.0586 256 15.0586ZM406.588 256V451.764C406.588 460.8 400.565 466.823 391.529 466.823H120.47C111.435 466.823 105.412 460.8 105.412 451.764V256C105.412 246.964 111.435 240.941 120.47 240.941H135.529H376.47H391.529C400.565 240.941 406.588 246.964 406.588 256ZM150.588 210.823V150.588C150.588 91.8586 197.27 45.1762 256 45.1762C314.729 45.1762 361.412 91.8586 361.412 150.588V210.823H150.588Z" fill="#808080"/>
<path d="M256 286.117C230.4 286.117 210.823 305.694 210.823 331.294C210.823 350.87 222.87 367.435 240.941 373.458V406.588C240.941 415.623 246.964 421.647 256 421.647C265.035 421.647 271.059 415.623 271.059 406.588V373.458C289.129 367.435 301.176 350.87 301.176 331.294C301.176 305.694 281.6 286.117 256 286.117ZM256 346.352C246.964 346.352 240.941 340.329 240.941 331.294C240.941 322.258 246.964 316.235 256 316.235C265.035 316.235 271.059 322.258 271.059 331.294C271.059 340.329 265.035 346.352 256 346.352Z" fill="#808080"/>
</svg>

    </div>
  )
}

export default Svg1
