import React from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar_offcanvas from './Navbar_offcanvas'

const Navbar = ({value}) => {
  const navigate=useNavigate()

  return (
    <div className='bg-transparent'>
        <div className="d-none d-lg-inline">
        <div className={`row m-0 py-3 container mx-auto`}>
            <div className="col-6 d-flex align-items-center">
                <img style={{cursor:'pointer'}} onClick={()=>{
                  navigate('/all-tests')
                }} src={require('../images/Logo_Blue 1 (1).png')} width={150} alt="" />
            </div>
            <div className="col-5 ms-auto d-flex align-items-center justify-content-between">
                <span style={{cursor:'pointer'}} onClick={()=>{
                  navigate('/all-tests')
                }}  className={`${value==='home' ? 'navbar':''} ${value==='project' ? 'text-white':''}`}>Home</span>
                <span style={{cursor:'pointer'}} onClick={()=>{
                  navigate('/projects')
                }} className={`${value==='project' ? 'navbar':''} ${value==='project' ? 'text-white':''}`}>Projects</span>
                <button onClick={()=>{
                  sessionStorage.removeItem('user')
                  navigate('/')
                }} className='btn text-white px-4 btn-sm d-flex align-items-center' style={{backgroundColor:'#185CFF',fontSize:'13px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
</svg>
<span className='ms-2' style={{fontSize:'14px'}}>Logout</span>
                </button>
            </div>
        </div>
        </div>

       <div className="d-lg-none">
       <div className='d-flex align-items-center justify-content-between px-2 py-3'>
        <img src={require('../images/Logo_Blue 1 (1).png')} width={110} alt="" />
        <svg style={{cursor:'pointer'}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-text-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
</svg>
        </div>
       </div>
        <Navbar_offcanvas/>
    </div>
  )
}

export default Navbar