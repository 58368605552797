import React, { useEffect, useRef, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { Chart as ChartJS } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'


const Admin_dashboard = () => {

  const chartRef = useRef(null);
 
  const employeecomparision = {
    labels: ['Python', 'React', 'Java', 'Javascript', 'HTML'],
    datasets: [
      {
        label: 'No of Students',
        data: [10, 6, 2, 1, 8],
        backgroundColor: function(context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case can happen on initial chart rendering, so return a default color
            return 'rgba(24,200,255,1)';
          }

          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, 'rgba(24,200,255,1)'); // Top color
          gradient.addColorStop(1, 'rgba(147,63,254,1)'); // Bottom color

          return gradient;
        },
        barThickness: 16,
        borderRadius: 10, // Optional: set bar border radius
      },
    ],
  };
  
  const options = {
    plugins: {
      legend: {
        position: 'bottom', // Set legend position to bottom
        labels: {
          boxWidth: 20, 
          maxWidth: 350, // Adjust this value as needed
        },
      },
    },
    // Other chart options
  };


  return (
    <div className='d-flex'>
      <Sidebar activevalue={"dashboard"}/>
      <div className="w-100 animate__animated animate__fadeIn">
        <Navbar/>
        <div className="main-container pt-3 pb-5">
          <div className='px-3'>
            <div style={{position:'relative'}}>
              <img src={require('../images/admin/Welcome-Widget.png')} style={{width:'100%'}} alt="" />
              <p className='fs-1 text-white fw-bold' style={{position:'absolute',top:'40%',left:'100px'}}>Welcome Back Admin!</p>
              {/* <p className='fs-5 text-white' style={{position:'absolute',top:'40%',left:'100px'}}></p> */}
            </div>

            <div className="row m-0 mt-4">
              <div className="col-lg-3 col-md-6">
                <div className='d-flex align-items-start p-4 justify-content-between' style={{backgroundColor:'#4E36E2',borderRadius:'15px'}}>
                  <img src={require('../images/admin/icon.png')} width={60} alt="" />
                  <div className='ms-3'>
                    <p className='m-0 text-white'>Regsitered Students</p>
                    <p className='fs-2 fw-bold m-0 text-white text-end'>20</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className='d-flex align-items-start p-4 justify-content-between' style={{backgroundColor:'#48A9F8',borderRadius:'15px'}}>
                  <img src={require('../images/admin/icon (1).png')} width={60} alt="" />
                  <div className='ms-3'>
                    <p className='m-0 text-white'>Categories Count</p>
                    <p className='fs-2 fw-bold m-0 text-white text-end'>20</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className='d-flex align-items-start p-4 justify-content-between' style={{backgroundColor:'#1BD084',borderRadius:'15px'}}>
                  <img src={require('../images/admin/icon (2).png')} width={60} alt="" />
                  <div className='ms-3'>
                    <p className='m-0 text-white'>Available Courses</p>
                    <p className='fs-2 fw-bold m-0 text-white text-end'>20</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className='d-flex align-items-start p-4 justify-content-between' style={{backgroundColor:'#4E36E2',borderRadius:'15px'}}>
                  <img src={require('../images/admin/icon (3).png')} width={60} alt="" />
                  <div className='ms-3'>
                    <p className='m-0 text-white'>Test Conducted</p>
                    <p className='fs-2 fw-bold m-0 text-white text-end'>20</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-0 mt-4 pt-3 ps-md-2">
              <div className="col-lg-8 bg-light shadow-sm p-4" style={{borderRadius:'15px'}}>
                <div className='mb-3'>
                  <h5>No of Students attended the test</h5>
                </div>
                <Bar data={employeecomparision} options={options}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admin_dashboard
