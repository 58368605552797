import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress, ipaddress2 } from '../App'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Backtotop from '../Pages/Backtotop'


const View_atteneded_coding_questions = () => {
    const[state,setstate]=useState('coding')

    const {id}=useParams()

    const downloadCertificate = () => {
      const input = document.getElementById('questions');
      html2canvas(input, { scale: 3, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('landscape', 'pt', [canvas.width, canvas.height]); // Set PDF dimensions based on the canvas
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0; // Align to the left
        const imgY = 0; // Align to the top
  
        pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save('answers.pdf');
      }).catch(error => {
        console.error('Error generating PDF:', error)
      })
    }

    const navigate=useNavigate()

    const[mcq_questions,setmcq_questions]=useState([])
    const[coding_questions,setcoding_questions]=useState([])

    const user=JSON.parse(sessionStorage.getItem('user'))

    useEffect(()=>{
        fetchcoding_questions()
    },[])

    const fetchcoding_questions=()=>{
      axios.get(`${ipaddress}/student/${id}/coding-questions/`)
      .then((r)=>{
          console.log("Attended coding questions",r.data)
          setcoding_questions(r.data)
      })
    }

    const [marks, setMarks] = useState({}); // Track marks in state with question IDs

const handleMarksChange = (newValue, q_id) => {
    // Update the local state first
    setMarks(prev => ({
        ...prev,
        [q_id]: newValue
    }));

    // Validate and update marks only if valid
    if (newValue === '' || !isNaN(newValue)) {
        update_marks(newValue, q_id);
    }
};

    const update_marks=(mark,q_id)=>{
            axios.post(`${ipaddress}/EditCodingMarks/`,{
                test_id:id,
                marks:mark,
                question_id:q_id
            })
            .then((r)=>{
                fetchcoding_questions()
            })
    }


  return (
    <div className='d-flex'>
        <Sidebar activevalue={"students"}/>
        <div className="w-100 animate__animated animate__fadeIn">
            <Navbar/>
            <div className="main-container py-3 pe-md-4 ps-md-2" style={{minHeight:'100vh'}}>
            <div className="d-flex pb-4 align-items-center justify-content-between">
            <h6 className='fs-5 m-0'>
                {/* <svg style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/view_modules/${id}`)
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg>  */}
<span className='ms-2'>Attended Coding Questions</span></h6>

<button className='btn btn-danger px-3' onClick={downloadCertificate}>Download PDF</button>
            </div>
            <div className='d-flex gap-2 gap-md-5 pb-2'>
<h5 onClick={()=>{
  setstate('coding')
  fetchcoding_questions()
}} className='py-2 px-4 d-flex align-items-center pb-3 fs-6' style={{color:state==='coding' ? '#57309C':'',cursor:'pointer',borderBottom:state==='coding' ? '2px solid #57309C':'none'}}>
  <span className=''>Coding Questions ({coding_questions.length})</span></h5>
            </div>


{/* Coding Questions */}
<div id='questions' className={`${state==='coding' ? '':'d-none'}`}>
    <h6 className={`text-center py-4 ${coding_questions.length>0 ? 'd-none':''}`} style={{cursor:'pointer'}} onClick={()=>{
        navigate('/assign_test')
    }}>No data vailable...✍</h6>

<div className={`${coding_questions.length>0 ? '':'d-none'} row m-0`}>
  {coding_questions.map((x,index)=>{
    return(
      <div className="col-12 px-2 mt-4">
  <div className='bg-white rounded shadow-sm p-4'>
    <p className='fw-medium text-primary' style={{fontSize:'17px'}}>{index+1}] <span className='fw-medium' style={{fontSize:'17px'}}>{x.question && x.question.question_text}</span></p>
    {x.question && x.question.imageforquestion && (
                            <img src={x.question.imageforquestion} className='my-3' style={{width:'30%'}} alt="" />
                        )}
    <div className="bg-light rounded p-3">
    {x.answer_text.split("\n").map((y)=>{
      return(
        <>
        <p className=''>{y}</p> 
        </>
      )
    })}
    </div>

    <div className='text-end mt-3'>
       <p className='m-0 fw-medium'>Enter the Mark :  <input type="text" style={{width:'70px'}} className='border  rounded py-2 px-2 shadow-none border-secondary-subtle'
       value={marks[x.question.question_id] ?? x.score ?? ''} // Default to the existing score or empty string
       onChange={(e) => {
           handleMarksChange(e.target.value, x.question.question_id);
       }} placeholder='Enter the Mark...' name="" id="" /></p>
    </div>

  </div>
</div>
    )
  })}
</div>
</div>
            </div>
        </div>
        <Backtotop/>
    </div>
  )
}

export default View_atteneded_coding_questions