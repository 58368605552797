import axios from 'axios'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ipaddress } from '../App'

const Mcq_excel = ({show,setshow,category,module_id,setcategory,setmodule_id,setskill_id}) => {
    
  const[mcq_excel,setmcq_excel]=useState(null)

  const upload_mcq_file=()=>{
    const form=new FormData()
    form.append('module',module_id)
    form.append('category',category)
    form.append('file',mcq_excel)

    axios.post(`${ipaddress}/upload-mcqs/`,form)
    .then((r)=>{
      toast.success('Questions uploaded successfully!',{
        autoClose:1000,
        theme:'colored',
        position:'top-center'
      })
      setcategory('')
      setmodule_id('')
      setskill_id('')
      setmcq_excel(null)

      setTimeout(() => {
        setshow(false)
      }, 1000);
    })
  }

  return (
    <div>
       <Modal show={show} onHide={()=>{
        setshow(false)
       }}>
        <Modal.Header className='d-flex align-items-center justify-content-between'>
          <Modal.Title className='fs-6 m-0'>Upload MCQ Question Excel</Modal.Title>
          <svg style={{cursor:'pointer'}} onClick={()=>{
            setshow(false)
          }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
        </Modal.Header>
        <Modal.Body>
            <div className='px-md-5 py-3'>
            <label htmlFor="file1" style={{cursor:'pointer'}} className={`justify-content-center d-flex align-items-center border border-success rounded text-success px-4 py-2 `}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-file-earmark-spreadsheet" viewBox="0 0 16 16">
  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5zM3 12v-2h2v2zm0 1h2v2H4a1 1 0 0 1-1-1zm3 2v-2h3v2zm4 0v-2h3v1a1 1 0 0 1-1 1zm3-3h-3v-2h3zm-7 0v-2h3v2z"/>
</svg> <span className='ms-2'>Upload Excel Sheet</span></label>
              <input className='d-none' type="file" accept=".xlsx,.xls" onChange={(e)=>{
                setmcq_excel(e.target.files[0])
              }} name="" id="file1" />

              <p className='text-success fw-medium mt-2 text-center' style={{fontSize:'14px'}}>{mcq_excel && mcq_excel.name}</p>

              <div className='text-center'>
                <button className='btn btn-sm px-4 btn-primary w-50 rounded-pill py-2 mt-2' onClick={()=>{
                    upload_mcq_file()
                }}>Submit</button>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Mcq_excel
