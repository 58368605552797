import React from 'react'
import Main_navbar from './Main_navbar'
import Main_footer from './Main_footer'
import Backtotop from './Backtotop'

const Services = () => {
  return (
    <div className='animate__animated animate__fadeIn' style={{minHeight:'100vh'}}>
        <Main_navbar value={"services"}/>

        <div className="main-container2 pt-4">
            <div className="container row m-0 mx-auto pb-5">
                <p className='fw-bold m-0 fs-3 text-center' style={{letterSpacing:'1px'}}>Find true power in your</p>
                <p className='fw-bold m-0 fs-3 text-center' style={{letterSpacing:'1px'}}>data with <span className='text-primary'>Ensome</span></p>
            </div>

        <div className='services-div'>

        </div>

        <div className="m-0 row align-items-center container mx-auto pt-5">
                <div className="col-md-6 mb-4 d-flex align-items-center justify-content-center py-5">
                    <div className='pe-4'>
                    <p className='fw-bold m-0 fs-3' style={{letterSpacing:'1px'}}>The <span className='text-primary'>newest</span></p>
                    <p className='fw-bold m-0 fs-3' style={{letterSpacing:'1px'}}>business analytics</p>
                    <p className='fw-bold m-0 fs-3' style={{letterSpacing:'1px'}}>platform</p>
                    </div>
                </div>
                <div className="col-md-6 mb-4 ps-md-0 py-5">
                    <p className='text-secondary'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
        </div>

        {/* Container 2 */}
        <div className="m-0 row align-items-center container mx-auto pb-5">

                <div className="col-md-6 mt-5">
                    <img src={require('../images/02_img_10.png')} style={{width:'100%'}} alt="" />
                </div>
                <div className="col-md-6 mt-5 d-flex align-items-center justify-content-center">
                    <div className='ps-4'>
                    <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Radically new solutions</p>
                    <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>for data</p>
                    {/* <img src={require('../images/Line.png')} alt="" /> */}
                    <p className='text-secondary mt-4'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    
                    <div className='mt-4'>
                        <button className='btn text-white px-5 py-2 fw-medium' style={{backgroundColor:'#185CFF',fontSize:'14px'}}>Learn more</button>
                       
                    </div>
                    </div>
                </div>
        </div>

        <div className="py-5">
            <p className='fw-bold fs-4 text-center'>We provide services that guarantee your success</p>
            <div className="row container m-0 mx-auto mt-4 pt-3">
                <div className="col-md-6 d-flex justify-content-evenly">
                    <div>
                        <p className='text-primary fw-bolder m-0 text-center fs-3'>1830+</p>
                        <p className='text-secondary text-center'>Project executed</p>
                    </div>
                    <div>
                        <p className='text-primary fw-bolder m-0 text-center fs-3'>1830+</p>
                        <p className='text-secondary text-center'>Project executed</p>
                    </div>
                    <div>
                        <p className='text-primary fw-bolder m-0 text-center fs-3'>1830+</p>
                        <p className='text-secondary text-center'>Project executed</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <p className='text-secondary'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
            </div>
        </div>

        <div className='' style={{backgroundColor:'#F1F6FA',paddingTop:'60px',paddingBottom:'70px'}}>
            
            <div className="row m-0 mt-4 container mx-auto">
                <div className="col-md-6 p-4 ps-md-0 d-flex align-items-center">
                    <div className=''>
                       <p className='fw-bold fs-3 m-0'>The benefits of Ensome </p>
                       <p className='text-secondary mt-3' style={{fontSize:'14px'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                       </div>
                </div>

                <div className="col-md-6">
                    <div className="row m-0">
                        <div className="col-md-6">
                        <div className='bg-white p-4 shadow' style={{height:'240px',borderRadius:'10px'}}>
                        <div className=''>
                        <img src={require('../images/Vector.png')} width={30} alt="" />

                            <p className='fw-medium m-0 mt-3' style={{fontSize:'20px'}}>Machine Learning</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>
                    </div>
                        </div>

                        <div className="col-md-6 mt-4">
                        <div className='bg-white p-4 shadow' style={{height:'240px',borderRadius:'10px'}}>
                        <div className=''>
                        <img src={require('../images/Vector (1).png')} width={30} alt="" />

                            <p className='fw-medium m-0 mt-3' style={{fontSize:'20px'}}>Embed analytics</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>
                    </div>
                        </div>

                        <div className="col-md-6">
                        <div className='bg-white p-4 shadow' style={{height:'240px',borderRadius:'10px'}}>
                        <div className=''>
                        <img src={require('../images/Vector (2).png')} width={30} alt="" />
                            <p className='fw-medium m-0 mt-3' style={{fontSize:'20px'}}>Access Control</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>
                    </div>
                        </div>

                        <div className="col-md-6 mt-4">
                        <div className='bg-white p-4 shadow' style={{height:'240px',borderRadius:'10px'}}>
                        <div className=''>
                        <img src={require('../images/Vector4.png')} width={30} alt="" />

                            <p className='fw-medium m-0 mt-3' style={{fontSize:'20px'}}>Machine Learning</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* Conatiner 3 */}
        <div className='py-5' style={{backgroundColor:'#FFFF'}}>
            <div className="container">
            <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Testimonials</p>
            {/* <img src={require('../images/Line.png')} alt="" /> */}
            </div>
            
            <div className="row m-0 mt-4 container mx-auto">
                <div className="col-md-6 col-lg-4 p-4 ps-md-0">
                    <div className='bg-white rounded p-4 shadow' style={{height:'350px'}}>
                        <div className='d-flex align-items-center'>
                        <img src={require('../images/001_img_person_1.png')} alt="" />
                        <div className='ms-3'>
                            <p className='fw-medium m-0' style={{fontSize:'14px'}}>Madhavan</p>
                            <p className='m-0 text-secondary mt-1' style={{fontSize:'13px'}}>Student of ICSE</p>
                        </div>
                        </div>
                        <p style={{color:'#9497A1',height:'140px'}} className='mt-4'>“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum.” </p>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4 p-4 pe-md-0">
                    <div className='bg-white rounded p-4 shadow' style={{height:'350px'}}>
                        <div className='d-flex align-items-center'>
                        <img src={require('../images/001_img_person_1.png')} alt="" />
                        <div className='ms-3'>
                            <p className='fw-medium m-0' style={{fontSize:'14px'}}>Jerold</p>
                            <p className='m-0 text-secondary mt-1' style={{fontSize:'13px'}}>Student of ICSE</p>
                        </div>
                        </div>
                        <p style={{color:'#9497A1',height:'140px'}} className='mt-4'>“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum.” </p>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4 p-4 pe-md-0">
                    <div className='bg-white rounded p-4 shadow' style={{height:'350px'}}>
                        <div className='d-flex align-items-center'>
                        <img src={require('../images/001_img_person_1.png')} alt="" />
                        <div className='ms-3'>
                            <p className='fw-medium m-0' style={{fontSize:'14px'}}>Jerold</p>
                            <p className='m-0 text-secondary mt-1' style={{fontSize:'13px'}}>Student of ICSE</p>
                        </div>
                        </div>
                        <p style={{color:'#9497A1',height:'140px'}} className='mt-4'>“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum.” </p>
                    </div>
                </div>
            </div>
        </div>

        {/* Container 4 */}
        <div className='py-5' style={{backgroundColor:'#ffff'}}>
            <div className='container'>
            <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Our Blogs</p>
            {/* <img src={require('../images/Line.png')} alt="" /> */}
            </div>
            <div className="row m-0 mt-4 container mx-auto">
            
            <div className="col-md-6 col-lg-4 p-4">
                <div class="card p-3">
  <img src={require('../images/img_8.png')} height={200} class="card-img-top" alt="..."/>
  <div class="card-body p-0 pt-3">
    <p className='text-secondary m-0' style={{fontSize:'13px'}}>29 JUN 2024</p>
    <h6 class="card-title mt-3 fw-medium">6 Strategies to Find Your Conference Keynote</h6>

    <p className='text-secondary mt-3' style={{fontSize:'14px'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas...</p>
    
    <p className='m-0 fw-medium mt-3 d-flex align-items-center' style={{fontSize:'13px',color:'#185CFF',cursor:'pointer'}}><span className='me-1'>Read more</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></p>
  </div>
</div>
                </div>

                <div className="col-md-6 col-lg-4 p-4">
                <div class="card p-3">
  <img src={require('../images/Rectangle 14.png')} height={200} class="card-img-top" alt="..."/>
  <div class="card-body p-0 pt-3">
    <p className='text-secondary m-0' style={{fontSize:'13px'}}>29 JUN 2024</p>
    <h6 class="card-title mt-3 fw-medium">6 Strategies to Find Your Conference Keynote</h6>

    <p className='text-secondary mt-3' style={{fontSize:'14px'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas...</p>
    
    <p className='m-0 fw-medium mt-3 d-flex align-items-center' style={{fontSize:'13px',color:'#185CFF',cursor:'pointer'}}><span className='me-1'>Read more</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></p>
  </div>
</div>
                </div>

                <div className="col-md-6 col-lg-4 p-4">
                <div class="card p-3">
                <img src={require('../images/img_8.png')} height={200} class="card-img-top" alt="..."/>
  <div class="card-body p-0 pt-3">
    <p className='text-secondary m-0' style={{fontSize:'13px'}}>29 JUN 2024</p>
    <h6 class="card-title mt-3 fw-medium">6 Strategies to Find Your Conference Keynote</h6>

    <p className='text-secondary mt-3' style={{fontSize:'14px'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas...</p>
    
    <p className='m-0 fw-medium mt-3 d-flex align-items-center' style={{fontSize:'13px',color:'#185CFF',cursor:'pointer'}}><span className='me-1'>Read more</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></p>
  </div>
</div>
                </div>
            </div>

            {/* <div className='text-center mt-2'>
                <button className='btn rounded-pill px-4 py-2 fw-medium' style={{border:'1px solid #3D37F1',color:'#3D37F1'}}>Load More</button>
            </div> */}
        </div>

        {/* Container 5 */}
        <div style={{backgroundColor:'#F1F6FA',paddingTop:'70px',paddingBottom:'70px'}} className=''>
            <div className="row container m-0 mx-auto">
               <p className='fw-bold fs-3 text-center'>Do you need help?</p>
               <p className='text-secondary text-center'>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi.</p>
               <div className='text-center mt-3'>
                <button className='btn text-white px-5 py-2' style={{backgroundColor:'#185CFF'}}>Contact Us</button>
               </div>
            </div>
        </div>
        </div>
        <Main_footer/>
        <Backtotop/>
    </div>
  )
}

export default Services