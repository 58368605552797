import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress } from '../App'
import { toast } from 'react-toastify'

const Add_details = () => {
    const[type,settype]=useState('category')

    // ADD CATEGORY
    const[cat_name,setcat_name]=useState('')
    const[cat_img,setcat_img]=useState(null)

    const add_category=()=>{
        const form=new FormData()
        form.append('name',cat_name)
        form.append('image',cat_img)

        axios.post(`${ipaddress}/mainstacks/`,form)
        .then((r)=>{
            setload(false)
            toast.success('Category added successfully',{
                theme:'colored',
                autoClose:1000,
                position:'top-center'
            })
            setcount(count+1)
            setcat_name('')
            setcat_img(null)
        })
    }


    // ADD SKILLS
    const[name,setname]=useState('')
    const[description,setdescription]=useState('')
    const[img,setimg]=useState(null)
    const[load,setload]=useState(false)
    const[skills,setskills]=useState([])
    const[categories,setcategories]=useState([])
    const[count,setcount]=useState(0)
    const[cat_id,setcat_id]=useState()

    useEffect(()=>{
        axios.get(`${ipaddress}/technical-skills/`)
        .then((r)=>{
            console.log("Technical skills",r.data)
            setskills(r.data)
        })
        axios.get(`${ipaddress}/mainstacks/`)
        .then((r)=>{
            console.log("Categories",r.data)
            setcategories(r.data)
        })
    },[count])
    
    const add_skill=()=>{
        setload(true)
        setTimeout(() => {
            add_technical_skills()
        }, 1000);
    }

    const add_technical_skills=()=>{
        const form=new FormData()
        form.append('name',name)
        form.append('mainstack',Number(cat_id))
        form.append('image',img)
        form.append('desc',description)

        axios.post(`${ipaddress}/technical-skills/`,form)
        .then((r)=>{
            setload(false)
            toast.success('Technical Skill added successfully',{
                theme:'colored',
                autoClose:1000,
                position:'top-center'
            })
            setcount(count+1)
            setname('')
            setdescription('')
            setimg(null)
        })
    }

      // ADD SKILLS
      const[module_name,setmodule_name]=useState('')
      const[time_limit,settime_limit]=useState('')
      const[module_img,setmodule_img]=useState(null)
      const[skill_id,setskill_id]=useState()

    const add_module=()=>{
        setload(true)
        setTimeout(() => {
            add_new_module()
        }, 1000);
    }

    const add_new_module=()=>{
        const form=new FormData()
        form.append('skill',Number(skill_id))
        form.append('name',module_name)
        form.append('image',module_img)
        form.append('time_limit',Number(time_limit))
        axios.post(`${ipaddress}/modules/create/`,form)
        .then((r)=>{
            setload(false)
            toast.success('Module added successfully',{
                theme:'colored',
                autoClose:1000,
                position:'top-center'
            })
            setmodule_name('')
            settime_limit('')
            setmodule_img(null)
            setskill_id('')
        })
    }

// Add Question Set

const[module_id,setmodule_id]=useState()
const[basic,setbasic]=useState(0)
const[intermediate,setintermediate]=useState(0)
const[advance,setadvance]=useState(0)
const[modules,setmodules]=useState([])

const fetch_modules=(id)=>{
    axios.get(`${ipaddress}/modules/?skill=${id}`)
    .then((r)=>{
        console.log("Modules",r.data)
        setmodules(r.data)
    })
}

const add_questionset=()=>{
    setload(true)
    setTimeout(() => {
        add_new_questionset()
    }, 1000);
}

const add_new_questionset=()=>{
    axios.post(`${ipaddress}/question-sets/`,{
        module:module_id,
        basic_count:Number(basic),
        intermediate_count:Number(intermediate),
        advanced_count:Number(advance)
    })
    .then((r)=>{
        console.log(r.data);
        
        setload(false)
        toast.success('Questionset added successfully',{
            theme:'colored',
            autoClose:1000,
            position:'top-center'
        })
        setmodule_id('')
        setskill_id('')
        setbasic(0)
        setintermediate(0)
        setadvance(0)
    })
}

const[eventdescription,seteventdescription]=useState('')
const[event_date,setevent_date]=useState('')
const[event_time,setevent_time]=useState('')
const[event_img,setevent_img]=useState(null)
const[event_type,setevent_type]=useState('')
const[event_link,setevent_link]=useState('')
const[amount,setamount]=useState(0)
const[gst_total,setgst_total]=useState(0)

const[allevents,setallevents]=useState([])

// const fetch_events=()=>{
//   axios.get(`${ipaddress2}/upcomming-events/`)
//   .then((r)=>{
//     setallevents(r.data)
//   })
// }

const add_event=()=>{
    setload(true)
    const form=new FormData()
    form.append('description',eventdescription)
    form.append('image',event_img)
    form.append('event_time',event_time)
    form.append('event_date',event_date)
    form.append('free_status',event_type)
    form.append('link',event_link)
    if(event_type==='cost'){
        form.append('fees',amount)
    form.append('gst_total',gst_total)
    }

    axios.post(`${ipaddress}/displayevents/`,form)
    .then((r)=>{
        setload(false)
        toast.success('Event added successfully',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
        setevent_img(null)
        setevent_date('')
        setevent_time('')
        seteventdescription('')
        setevent_type('')
        setgst_total(0)
        setevent_link('')
        setamount(0)
        // fetch_events()
    })
    .catch((err)=>{
        setload(false)
        console.log(err)
    })

}

return (
    <div className='d-flex'>
      <Sidebar activevalue={"add_details"}/>
      <div className="w-100 animate__animated animate__fadeIn">
        <Navbar/>

        <div className="py-3 main-container">
       <div className='d-flex align-items-center'>
       <div className='p-2'>
        <p onClick={()=>{
                        settype('category')
                    }} className='py-2 justify-content-center px-4 d-flex align-items-center pb-3' style={{color:type==='category' ? '#57309C':'',cursor:'pointer',borderBottom:type==='category' ? '2px solid #57309C':'none'}}>Add Category</p>
        </div>
       <div className='p-2'>
        <p onClick={()=>{
                        settype('skill')
                    }} className='py-2 justify-content-center px-4 d-flex align-items-center pb-3' style={{color:type==='skill' ? '#57309C':'',cursor:'pointer',borderBottom:type==='skill' ? '2px solid #57309C':'none'}}>Add Technical Skill</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('module')
                    }} className='py-2 justify-content-center px-4 d-flex align-items-center pb-3' style={{color:type==='module' ? '#57309C':'',cursor:'pointer',borderBottom:type==='module' ? '2px solid #57309C':'none'}}>Add Module</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('question_set')
                    }} className='py-2 justify-content-center px-4 d-flex align-items-center pb-3' style={{color:type==='question_set' ? '#57309C':'',cursor:'pointer',borderBottom:type==='question_set' ? '2px solid #57309C':'none'}}>Add Question Set</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('event')
                    }} className='py-2 justify-content-center px-4 d-flex align-items-center pb-3' style={{color:type==='event' ? '#57309C':'',cursor:'pointer',borderBottom:type==='event' ? '2px solid #57309C':'none'}}>Add Event</p>
        </div>
       </div>

 {/* ADD CATEGORY */}
 <div className={`${type==='category' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add New Category</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Category Name</label>
                <input type="text" value={cat_name} onChange={(e)=>{
                    setcat_name(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-3 mt-2' />
                </div>

                <div className='mb-3 col-md-6 d-flex align-items-end mt-2'>
                <label style={{cursor:'pointer'}} htmlFor="category-file" className='bg-primary text-white rounded px-4 py-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z"/>
  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
</svg><span className='ms-2'>Upload the Image</span></label>
                <input accept='image/*' type="file" onChange={(e)=>{
                    setcat_img(e.target.files[0])
                }} id='category-file' className='d-none' />
                {/* <p className='text-success m-0 ms-2'>{img && img.name}</p> */}
                {cat_img && (
                    <img className='ms-3' src={URL.createObjectURL(cat_img)} width={40} alt="" />
                )}
                </div>

<div className='text-end mt-3'>
    <button disabled={cat_img !==null ? false:true} className='btn btn-primary px-4 py-2' onClick={add_category}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span></button>
</div>
            </div>
        </div>
    </div>

</div>

       {/* ADD SKILLS */}
       <div className={`${type==='skill' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add New Technical Skill</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
            <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Category</label>
                <select type="text" value={cat_id} onChange={(e)=>{
                    setcat_id(e.target.value)
                }} className='form-select bg-light shadow-none border-secondary-subtle py-3 mt-2'>
<option value="">Select Category...</option>
{categories.map((x)=>{
    return(
        <>
        <option value={x.mainstack_id}>{x.name}</option>
        </>
    )
})}


                </select>
                </div>
                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Skill Name</label>
                <input type="text" value={name} onChange={(e)=>{
                    setname(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-3 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Description</label>
                <input type="text" value={description} onChange={(e)=>{
                    setdescription(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-3 mt-2' />
                </div>

                <div className='mb-3 col-md-6 d-flex align-items-end mt-2'>
                <label style={{cursor:'pointer'}} htmlFor="event-file" className='bg-primary text-white rounded px-4 py-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z"/>
  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
</svg><span className='ms-2'>Upload the Image</span></label>
                <input type="file" onChange={(e)=>{
                    setimg(e.target.files[0])
                }} id='event-file' className='d-none' />
                <p className='text-success m-0 ms-2'>{img && img.name}</p>
                </div>

<div className='text-end mt-3'>
    <button disabled={img !==null ? false:true} className='btn btn-primary px-4 py-2' onClick={add_skill}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span></button>
</div>
            </div>
        </div>
    </div>

</div>

       {/* ADD MODULE */}
       <div className={`${type==='module' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add New Module</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
            <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Technical Skill</label>
                <select type="text" value={skill_id} onChange={(e)=>{
                    setskill_id(e.target.value)
                }} className='form-select bg-light shadow-none border-secondary-subtle py-3 mt-2'>
<option value="">Select Technical Skill...</option>
{skills.map((x)=>{
    return(
        <>
        <option value={x.tech_id}>{x.name}</option>
        </>
    )
})}


                </select>
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Module Name</label>
                <input type="text" value={module_name} onChange={(e)=>{
                    setmodule_name(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-3 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Time Limit</label>
                <input type="text" value={time_limit} onChange={(e)=>{
                    settime_limit(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-3 mt-2' />
                </div>

                <div className='mb-3 col-md-6 d-flex align-items-end mt-2'>
                <label style={{cursor:'pointer'}} htmlFor="event-file2" className='bg-primary text-white rounded px-4 py-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z"/>
  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
</svg><span className='ms-2'>Upload the Image</span></label>
                <input type="file" onChange={(e)=>{
                    setmodule_img(e.target.files[0])
                }} id='event-file2' className='d-none' />
                <p className='text-success m-0 ms-2'>{module_img && module_img.name}</p>
                </div>

<div className='text-end mt-3'>
    <button disabled={module_img !==null ? false:true} className='btn btn-primary px-4 py-2' onClick={add_module}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span></button>
</div>
            </div>
        </div>
    </div>

</div>

{/* ADD QUESTION SET */}
<div className={`${type==='question_set' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add Question Set</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
            <div className='mb-4 col-md-6'>
                <label htmlFor="" className='fw-medium'>Technical Skill</label>
                <select type="text" value={skill_id} onChange={(e)=>{
                    setskill_id(e.target.value)
                    fetch_modules(e.target.value)
                }} className='form-select bg-light shadow-none border-secondary-subtle py-3 mt-2'>
<option value="">Select Technical Skill...</option>
{skills.map((x)=>{
    return(
        <>
        <option value={x.tech_id}>{x.name}</option>
        </>
    )
})}
                </select>
                </div>

                <div className='mb-4 col-md-6'>
                <label htmlFor="" className='fw-medium'>Module</label>
                <select type="text" value={module_id} onChange={(e)=>{
                    setmodule_id(e.target.value)
                }} className='form-select bg-light shadow-none border-secondary-subtle py-3 mt-2'>
<option value="">Select Module...</option>
{modules.map((x)=>{
    return(
        <>
        <option value={x.module_id}>{x.name}</option>
        </>
    )
})}
                </select>
                </div>


                <div className='mb-3 col-md-4'>
                <label htmlFor="" className='fw-medium'>Basic Count</label>
                <input type="text" value={basic} onChange={(e)=>{
                    setbasic(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-3 mt-2' />
                </div>

                <div className='mb-3 col-md-4'>
                <label htmlFor="" className='fw-medium'>Intermediate Count</label>
                <input type="text" value={intermediate} onChange={(e)=>{
                    setintermediate(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-3 mt-2' />
                </div>

                <div className='mb-3 col-md-4'>
                <label htmlFor="" className='fw-medium'>Advance Count</label>
                <input type="text" value={advance} onChange={(e)=>{
                    setadvance(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-3 mt-2' />
                </div>

<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' onClick={add_questionset}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span></button>
</div>
            </div>
        </div>
    </div>

</div>


{/* Add Event */}
<div className={`${type==='event' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add New Event</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Name</label>
                <input type="text" value={eventdescription} onChange={(e)=>{
                    seteventdescription(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Date</label>
                <input type="date" value={event_date} onChange={(e)=>{
    const today = new Date();
    const selectedDate = new Date(e.target.value);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
    else{
        setevent_date(e.target.value)
    }
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Time</label>
                <input type="time" value={event_time} onChange={(e)=>{
                    setevent_time(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Link</label>
                <input type="text" value={event_link} onChange={(e)=>{
                    setevent_link(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Type</label>
                <select name="" value={event_type} onChange={(e)=>{
                    setevent_type(e.target.value)
                }} id="" className='form-select shadow-none border-secondary-subtle mt-2 py-2'>
                    <option value="">Choose...</option>
                    <option value="cost">Paid Event</option>
                    <option value="free">Free Event</option>
                </select>
                </div>

                <div className={`mb-3 col-md-6 ${event_type==='cost' ? '':'d-none'}`}>
                <label htmlFor="" className='fw-medium'>Amount</label>
                <input type="number" value={amount} onChange={(e)=>{
                    setamount(e.target.value)
                    setgst_total(e.target.value*0.18)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className={`mb-3 col-md-6 ${event_type==='cost' ? '':'d-none'}`}>
                <label htmlFor="" className='fw-medium'>GST Total</label>
                <input type="number" value={gst_total} onChange={(e)=>{
                    setgst_total(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label style={{cursor:'pointer'}} htmlFor="event-file2" className=''><span className='fw-medium'>Upload Image</span></label>
                <input type="file" onChange={(e)=>{
                    setevent_img(e.target.files[0])
                }} id='event-file2' className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                
                {/* <p className='text-success m-0 ms-2'>{event_img && event_img.name}</p> */}
                {event_img && (
                    <img src={URL.createObjectURL(event_img)} className='mt-3' style={{width:'80px'}}/>
                )}
                </div>

<div className='text-end mt-3'>
    <button disabled={event_img !==null ? false:true} className='btn btn-primary px-4 py-2' onClick={add_event}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span></button>
</div>
            </div>
        </div>
    </div>

</div>
        </div>
      </div>
    </div>
  )
}

export default Add_details
