import React from 'react'

const Navbar = () => {
  return (
    <div className='nav-container border-bottom' style={{position:'fixed',zIndex:10,height:'78px',width:'100%',backgroundColor:'#F2F2F2'}}>
     <div className="m-0 h-100 row">
     <div className='col-4 ms-auto d-flex align-items-center'>
        <img src={require('../images/user.png')} width={50} alt="" />
        <div className='ms-1'>
          <p className='m-0 fw-medium'>Athirupan</p>
          <p className='m-0 text-secondary' style={{fontSize:'14px'}}>Admin</p>
        </div>
      </div>
     </div>
    </div>
  )
}

export default Navbar
