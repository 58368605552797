import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Backtotop from './Backtotop';
import axios from 'axios';
import { ipaddress } from '../App';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Preloader from './Preloader';

const Coding_test = () => {

    const navigate=useNavigate()
  const [code, setCode] = useState('');

  const {id}=useParams()

    const[count,setcount]=useState(0)

    const[all_questions,setall_questions]=useState([])

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[test_data,settest_data]=useState({})
    const [overlayVisible, setOverlayVisible] = useState(false);

    const[q_id,setq_id]=useState(1)
    const[state,setstate]=useState(false)
    const [timeLeft, setTimeLeft] = useState(); // Total seconds (2 hours)

    const[load,setload]=useState(false)
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0 && load==false) {
        setTimeLeft(timeLeft - 1);
      }
      if(timeLeft==0){
        setstate(true)

        setTimeout(() => {
          navigate('/success')
        }, 2000);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft,load]);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;
    
    useEffect(()=>{
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen().catch(err => {
            console.error("Failed to enter full-screen mode:", err);
        });
    }

    // Prevent context menu
    document.addEventListener('contextmenu', (event) => {
        event.preventDefault();
    });

    // Handle Escape key
    const handleEscKey = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            setOverlayVisible(true);
        }
    };

    window.addEventListener('keydown', handleEscKey);

    // Handle window blur
    const handleWindowBlur = () => {
        setOverlayVisible(true);
    };

    window.addEventListener('blur', handleWindowBlur);

       fetch_all_questions()

       axios.post(`${ipaddress}/tests/`,{
        student:user.id,
        module:id,
        coding:true
       })
        .then((r)=>{
            // console.log("Tests",r.data)
            settest_data(r.data)
            setTimeLeft(r.data.time_limit)
        })


      window.addEventListener('blur', handleWindowBlur);

      return () => {
          window.removeEventListener('keydown', handleEscKey);
      window.removeEventListener('blur', handleWindowBlur);
          document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    },[])

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
          // The user exited full-screen mode
          // alert("You exited full-screen mode! The test will be submitted.");
          setOverlayVisible(true)
          // submit_test();
      }
  };

  const handleBeforeUnload = (event) => {
    // Set a flag in the session storage
    sessionStorage.setItem('test_in_progress', 'true');
    submit_test()
};
  

  
// Handle visibilitychange event
const handleVisibilityChange = () => {
  if (document.hidden) {
      // submit_test()
  }
};

  // const handlePopState = () => {
  //     navigate('/success', { replace: true });
  // };

  const handleOverlayAction = (action) => {
    if (action === 'submit') {
        submit_test();
    } else if (action === 'return') {
        // Return to full-screen mode
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen()
                .catch(err => {
                    console.error("Failed to re-enter full-screen mode:", err);
                });
        }
    }
    setOverlayVisible(false); // Hide the overlay after action
};

const[loading,setloading]=useState(false)

    const fetch_all_questions=()=>{
      setloading(true)
        axios.get(`${ipaddress}/modules/${id}/coding-questions/`)
        .then((r)=>{
            console.log("All coding questions",r.data);
            setall_questions(r.data)
            setquestion(r.data[0])
            setloading(false)
        })
    }

    const[question,setquestion]=useState({})
    const[current_qid,setcurrent_qid]=useState(0)

    useEffect(()=>{
        if(q_id>0){
          const data=all_questions.find(x=>x.question_no===q_id)
        console.log("Question",data);
        // console.log("Question id",data.id);
        if(data){
        console.log("Question id",data.id);
          setcurrent_qid(data.id)
        setquestion(data)
        }
        setCode('')
        }
    },[q_id])
    
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleSubmit = (value) => {
   setload(true)
   setTimeout(() => {
    send(value)
   }, 1000);
  };

  const send=(value)=>{
    axios.post(`${ipaddress}/SingleCodingAnswerCreateView/`,{
        test_id:test_data.test_id,
        question_id:question.id,
        answers:code
       })
       .then((r)=>{
        // setCode('')
        setload(false)
        toast.success('Code submitted!',{
            theme:'colored',
            position:'top-center',
            autoClose:2000
        })
        if(value==='next'){
        setq_id(q_id+1)
        }
        if(value==='previous'){
          setq_id(q_id-1)
        }
        // fetch_all_questions()
       })
  }

// To provide 8 spaces while giving space 
// const handleKeyDown = (e) => {
//     if (e.key === ' ') {
//       e.preventDefault();
//       const { selectionStart, selectionEnd } = e.target;
//       const newCode = `${code.substring(0, selectionStart)}        ${code.substring(selectionEnd)}`;
//       setCode(newCode);
//       setTimeout(() => {
//         e.target.selectionStart = e.target.selectionEnd = selectionStart + 8;
//       }, 0);
//     }
//   };

// To fetch the answer for previous question
const[count1,setcount1]=useState(0)
const previous=(value)=>{
  console.log("Current question number",value)

  const previous_question=all_questions.find(x=>x.question_no===value)
  // console.log(previous_question)

  axios.get(`${ipaddress}/SendQuestionAndAnswer/${previous_question.id}/${user.id}/`)
  .then((r)=>{
    console.log('prev ques',r.data)
    console.log("Previous answer",r.data.answer.answer_text)
    setCode(r.data.answer.answer_text)
    setcount1(count1+1)
  })
}

useEffect(()=>{
if(count1>0){
  console.log("ANSWER TEXT",code)
  setq_id(q_id-1)
}
},[count1])


  const submit_test=()=>{
    axios.post(`${ipaddress}/SingleCodingAnswerCreateView/`,{
      test_id:test_data.test_id,
      question_id:question.id,
      answers:code
     })
     .then((r)=>{
      // setCode('')
      setload(false)
      if(document.exitFullscreen){
        document.exitFullscreen()
      }
       navigate('/success')
     })
     
      //  })
  }

  useEffect(()=>{
    const questionContainer=document.querySelector('question-container')

    const handleScroll=(e)=>{
        e.target.style.overflowY='auto'
    }

    if (questionContainer) {
        questionContainer.addEventListener('scroll', handleScroll);
      }

      return ()=>{
        
    if (questionContainer) {
        questionContainer.removeEventListener('scroll', handleScroll);
      }
      }
},[])

return (
    <div className='test-page' style={{ minHeight: '100vh', backgroundColor: '#F1F6FA' }}>
      {/* <Navbar /> */}

      {loading ? (<Preloader/>):(
        <div className='pt-5 container pb-3'>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <p className='fw-bold m-0' style={{color:'#F5C345'}}>
              Coding Test : <span className='fw-bold text-white'>{test_data.module_name}</span>
            </p>
            <p className='fw-medium mt-2' style={{color:'#F5C345'}}>
              Time Left : <span className='fw-medium text-white'> {hours.toString().padStart(2, '0')} : {minutes.toString().padStart(2, '0')} : {seconds.toString().padStart(2, '0')}</span>
            </p>
          </div>
          <div className='d-flex align-items-center justify-content-end'>
                     <div className='me-3'>
                     <p className='fw-bold m-0 text-end text-white'>{user.username}</p>
                     <p className='fw-medium text-end mt-1 text-white m-0' style={{fontSize:'13px'}}>Profile</p>
                     </div>
                     <img src={require('../images/Clip path group.png')} alt="" />
                 </div>
        </div>

        <div className=''>
          <div className='row m-0 mt-4'>
            <div className="col-md-6 ps-md-0 mb-5 mb-md-0">
              <div className='question-container options-div p-5' style={{ backgroundColor: '#FFFF', borderRadius: '15px', height: '560px',overflowY:'hidden'}}>
                <p className='fw-medium text-white'><span className='me-2'>{q_id})</span>{question && question.question_text && question.question_text}</p>
                {question && question.imageforquestion && question.imageforquestion!=undefined && (
                            <img src={question.imageforquestion && question.imageforquestion} className='my-2' style={{width:'50%'}} alt="" />
                        )}
                {question && question.sample_input && (<p className='m-0 fw-bold mt-4 mb-1 text-white' style={{fontSize:'14px'}}>Sample Input:</p>)}
                <p style={{fontSize:'13px'}}>{question && question.sample_input && question.sample_input}</p>

                {question && question.sample_ouput && (<p className='m-0 fw-bold mt-4 mb-1 text-white' style={{fontSize:'14px'}}>Sample Output:</p>)}
                <p style={{fontSize:'13px'}}>{question && question.sample_ouput && question.sample_output}</p>

                {question && question.constraints && (<p className='m-0 fw-bold mt-4 mb-1 text-white' style={{fontSize:'14px'}}>Constraints:</p>)}
                <p style={{fontSize:'13px'}}>{question && question.constraints && question.constraints}</p>

                {question && question.explanation && (<p className='m-0 fw-bold mt-4 mb-1 text-white' style={{fontSize:'14px'}}>Explanation:</p>)}
                <p style={{fontSize:'13px'}}>{question && question.explanation && question.explanation}</p>
                
              </div>
            </div>

            <div className="col-md-6 pe-md-0 ps-md-4">
              <div className={`${question && question.fillintheblank ? 'd-none':''}`} style={{ backgroundColor: '#13133C', borderRadius: '15px', height: '560px'}}>
                <p className='text-white fw-medium m-0 text-center border-bottom py-3' style={{fontSize:'13px'}}>Type Your Answer</p>
                <textarea
                  className='form-control shadow-none border-0 text-white py-2'
                  style={{ height: '80%', resize: 'none',fontSize:'13px',backgroundColor:'#13133C',overflowY:'scroll'}}
                  value={code}
                  onChange={handleCodeChange}
                  // onKeyDown={handleKeyDown}
                ></textarea>
                <div className='text-center mt-auto border-top pt-3'>
                    <button onClick={handleSubmit} disabled className='btn rounded-pill text-primary bg-white btn-sm px-4 fw-medium'>
                    <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
                    <span className={`${load ? 'ms-2':'d-none'}`} role="status">Saving...</span>
                    <span className={`${load ? 'd-none':''}`}>Save</span>
                    </button>
                </div>
              </div>

              <div className={`border bg-light shadow-sm border-secondary-subtle ${question && question.fillintheblank ? '':'d-none'}`} style={{borderRadius: '15px', height: '560px' }}>
                <p className='text-dark fw-medium m-0 text-center border-bottom py-3' style={{fontSize:'13px'}}>Type your Answer here</p>
                <textarea
                  className='form-control shadow-none border-0 py-2'
                  style={{ height: '80%', resize: 'none',fontSize:'13px',backgroundColor:'#ffff'}}
                  value={code}
                  onChange={handleCodeChange}
                  // onKeyDown={handleKeyDown}
                ></textarea>
                <div className='text-center mt-auto border-top pt-3'>
                    <button disabled={load ? true:false} onClick={handleSubmit} className='btn rounded-pill text-primary bg-white btn-sm px-4 fw-medium'>
                    <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
                    <span className={`${load ? 'ms-2':'d-none'}`} role="status">Saving...</span>
                    <span className={`${load ? 'd-none':''}`}>Save</span>
                    </button>
                </div>
              </div>
            </div>
          </div>

          <div className={`d-flex align-items-center mb-5 mb-md-0 mt-4 pt-2 ${q_id===1 ? 'justify-content-between':'justify-content-between'}`}>
            {/* <button className='btn btn-white border border-secondary-subtle fw-medium px-4 py-2' style={{ color: '#185CFF', fontSize: '15px' }}>Prev</button> */}
            <button onClick={()=>{
                // previous(q_id-1)
                handleSubmit('previous')
            }} className={`btn btn-light fw-medium px-4 py-2 ${q_id===1 ? 'd-none':''}`} style={{ color: '#185CFF', fontSize: '15px' }}>Previous</button>
              <button
              className={`btn text-white border fw-medium px-4 py-2`}
              style={{ color: '#185CFF', fontSize: '15px'}}
              onClick={()=>{
                submit_test()
              }}
            >
              Submit Test
            </button>
            <button onClick={()=>{
                handleSubmit('next')
            }} className={`btn btn-light fw-medium px-4 py-2 ${all_questions.length===q_id ? 'd-none':''}`} style={{ color: '#185CFF', fontSize: '15px' }}>Next</button>
          
          </div>
        </div>

        
      </div>
      )}

         {/* CUSTOM POPUP */}
         <div className={overlayVisible ? 'overlay' : 'd-none'} style={{ 
                    position: 'fixed', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100vh', 
                    backgroundColor: 'rgba(0,0,0,0.6)', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    zIndex: 10 
                }}>
                    <div className='text-center' style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
                        <p className='fw-bold fs-5'>You switched away from the test!</p>
                        <p>The test will be submitted now.</p>
                        <button className='btn btn-primary mx-2' onClick={() => handleOverlayAction('submit')}>OK</button>
                        <button className='btn btn-secondary mx-2' onClick={() => handleOverlayAction('return')}>Back</button>
                    </div>
                </div>
      {/* <Footer /> */}
      <Backtotop />

      {/* Layout */}
<div className={state ? 'animate__animated animate__fadeIn':'d-none'} style={{backgroundColor:'rgba(0,0,0,0.6)',width:'100%',height:'100vh',position:'fixed',top:0,left:0,zIndex:6}}>
<div className="row justify-content-center">
    <div className="bg-white col-md-6 p-2 mt-3" style={{borderRadius:'10px'}}>
        <div className='py-4 mb-2'>
           <p className='fw-bold fs-5 text-center m-0'>Exam Time Duration Completed!!!</p>
        </div>
    </div>
</div>
</div>
    </div>
  );
};

export default Coding_test;
