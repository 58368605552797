import React, { useEffect, useState } from 'react'
// import Project_navbar from './Project_navbar'
import axios from 'axios'
import { ipaddress, ipaddress2 } from '../App'
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import Navbar from '../Pages/Navbar';
// import Footer from './Footer';
// import Preloader from '../Components/Preloader';

const Project_dashboard = () => {

    const navigate=useNavigate()

  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const[loading,setloading]=useState()

    const[categories,setCategories]=useState([])

    useEffect(()=>{
      setloading(true)
        axios.get(`${ipaddress2}/CategoryChoicesView/`)
        .then((r)=>{
            console.log(r.data)
            setCategories(r.data)
            setloading(false)
        })
    },[])


  return (
       <div className='' style={{minHeight:'100vh'}}>
       
       
       <div className='project-main-div' style={{height:'100vh'}}>
       <Navbar value={"project"}/>

       <div className="" style={{height:'90%'}}>

       </div>
       </div>
       <div className='container' style={{paddingBottom:'100px'}}>

   <div className="row mt-5" style={{minHeight:'400px',paddingTop:'30px'}}>
    <div className="col-1">
      <div className='sub-div4 d-flex align-items-center justify-content-center' style={{borderRadius:'10px',height:'100%'}}>
        <p className='vertical-text fw-bold text-white' style={{letterSpacing:'1px'}}>Category</p>
      </div>
    </div>
    <div className="col-11">
    <div className='row m-0'>
                 {categories.map((x,index)=>{
                  return(
                    <div className={`col-md-6 mb-4 ${index%2==0 ? 'ps-md-0':'pe-md-0'}`}>
                    <div className='sub-div3 d-flex align-items-center px-3' style={{position:'relative'}}>
                        <img src={require('../images/Daco_1896670 1.png')} width={160} height={150} className='' alt="" />
                        <div className='ps-3 '>
                            <p className='text-white fw-bold m-0' style={{fontSize:'20px'}}>{x.category_name && x.category_name.slice(0,60)}</p>
                            <p className='text-white mt-2 m-0' style={{fontSize:'13px',lineHeight:'20px'}}>{x.description && x.description.slice(0,100)}...</p>
                        </div>
                        <svg onClick={()=>{
                          navigate(`/view_category/${x.id}`)
                        }} style={{position:'absolute',right:'10px',bottom:'7px',cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg>
                    </div>
                </div>
                  )
                 })}
            </div>
    </div>
 
   </div>
          
       </div>
       {/* <Footer/> */}
   </div>
  )
}

export default Project_dashboard