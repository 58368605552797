import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom'
import Backtotop from './Backtotop'
import axios from 'axios'
import { ipaddress } from '../App'

const Main_project_page = () => {
    const navigate=useNavigate()

    const[technical_skills,settechnical_skills]=useState([])

    useEffect(()=>{
        axios.get(`${ipaddress}/technical-skills/`)
        .then((r)=>{
            console.log("Technical skills",r.data);
            settechnical_skills(r.data)
        })
    },[])


return (
    <div className='animate__animated animate__fadeIn' style={{backgroundColor:'#F1F6FA',minHeight:'100vh'}}>
        <Navbar value={"projects"}/>
        <div className='pt-4 main-container2 container'>
            <p className='m-0 fs-3 fw-medium text-center'>Discover Your True Potential with Our</p>
            <p className='fs-3 fw-medium text-center'>Skill Assessment Tests</p>
            <div className='text-center mb-4'>
                <button className='btn btn-primary px-3 py-2' style={{fontSize:'14px'}}>Project Categories</button>
            </div>
            <div className='row m-0 mt-4 pt-3'>
               {technical_skills.map((x,index)=>{
                return(
                    <div key={index} className={`col-md-6 d-flex justify-content-center ${index%2==0 ? 'ps-md-0':'pe-md-0'} mb-4 mt-2`}>
                    <div className='sub-div d-flex align-items-center px-3' style={{position:'relative'}}>
                    <img src={x.image} width={160} height={110} className='' alt="" />
                        {/* <img src={require('../images/5881554 1.png')} width={160} height={110} className='' alt="" /> */}
                        <div className='ps-3 '>
                            <p className='text-white fw-medium m-0' style={{fontSize:'20px'}}>{x.name}</p>
                            <p className='text-white mt-2 m-0' style={{fontSize:'13px'}}>{x.desc}</p>
                        </div>
                        <svg onClick={()=>{
                            navigate(`/subpage/${x.tech_id}`)
                        }} style={{position:'absolute',right:'20px',bottom:'16px',cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg>
                    </div>
                </div>
                )
               })}
            </div>
        </div>
        <Footer/>
        <Backtotop/>
    </div>
  )
}

export default Main_project_page