import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ipaddress } from '../App'
import axios from 'axios'

const Sidebar = ({activevalue}) => {
 
  const[open,setOpen]=useState(true)
  const[value,setValue]=useState("")
  useEffect(()=>{
    setValue(activevalue)
  },[])


//   // Logout

//   const navigate=useNavigate()

//   const handleLogout=()=>{
//     axios.post(`${ipaddress}/User_Logout/${user.emp_id}/`)
//     .then((r)=>{
//       sessionStorage.removeItem("user")
//       navigate('/')
//     })
//     .catch((err)=>{
//       console.log("Logout Error",err)
//     })
//   }
  return (
    <div style={{width:open ? '230px' : '60px',backgroundColor:'#40189D',zIndex:20}} className='sidebar d-none d-lg-flex'>
      <div style={{width:'210px'}}>
      <div className='px-4 d-flex align-items-center justify-content-center' style={{position:'relative',height:'90px'}}>
                <img className={open ? 'd-inline' : 'd-none'} src={require('../images/admin/Logo_Blue 1.png')} style={{width:'86%'}} alt="" />
              
            </div>


<ul class={`nav flex-column ${open ? 'w-100' : ''} mx-auto ps-2`}>
  
<li className='sidebar-li'>
<li class={`nav-item navbar-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="dashboard" ? 'sidebar-color':''}`}>
    <img src={require('../images/background.png')} className={value==='dashboard' ? '':'d-none'} style={{width:'100%',position:'absolute',top:'-30px',zIndex:1}} alt="" />

    <Link to='/admin_dashboard' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`w-25 bi bi-house-fill  ${value==="dashboard"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} viewBox="0 0 16 16">
  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z"/>
  <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293z"/>
</svg>
    <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="dashboard"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} style={{fontSize:'14px'}}>Dashboard </span></Link>
  </li>
</li>

<li className='sidebar-li'>
  <li class={`nav-item navbar-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="add_details" ? 'sidebar-color':''}`}>
  <img src={require('../images/background.png')} className={value==='add_details' ? '':'d-none'} style={{width:'100%',position:'absolute',top:'-30px',zIndex:1}} alt="" />
    <Link to='/add_details' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`w-25 bi bi-journals  ${value==="add_details"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} viewBox="0 0 16 16">
  <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2"/>
  <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0"/>
</svg>
    <span className={`${open ? 'w-75 d-inline' : 'd-none'} ${value==="add_details"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} style={{fontSize:'14px'}}>Add Details </span></Link>
  </li>
  </li>

  <li className='sidebar-li'>
  <li class={`nav-item navbar-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="skills" ? 'sidebar-color':''}`}>
  <img src={require('../images/background.png')} className={value==='skills' ? '':'d-none'} style={{width:'100%',position:'absolute',top:'-30px',zIndex:1}} alt="" />
    
    <Link to='/technical_skills' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-journal-check w-25 ${value==="skills"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg>
    <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="skills"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} style={{fontSize:'14px'}}>Technical Skills</span></Link>
  </li>
  </li>

<li className='sidebar-li'>
  <li class={`nav-item navbar-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="create_questions" ? 'sidebar-color':''}`}>
  <img src={require('../images/background.png')} className={value==='create_questions' ? '':'d-none'} style={{width:'100%',position:'absolute',top:'-30px',zIndex:1}} alt="" />
    <Link to='/create_questions' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-folder-plus w-25 ${value==="create_questions"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} viewBox="0 0 16 16">
  <path d="m.5 3 .04.87a2 2 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2m5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19q-.362.002-.683.12L1.5 2.98a1 1 0 0 1 1-.98z"/>
  <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5"/>
</svg>
    <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="create_questions"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} style={{fontSize:'14px'}}>Create Questions</span></Link>
  </li>
  </li>

  <li className='sidebar-li'>
  <li class={`nav-item navbar-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="students" ? 'sidebar-color':''}`}>
  <img src={require('../images/background.png')} className={value==='students' ? '':'d-none'} style={{width:'100%',position:'absolute',top:'-30px',zIndex:1}} alt="" />
    <Link to='/students' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-people w-25 ${value==="students"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
    <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="students"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} style={{fontSize:'14px'}}>Students</span></Link>
  </li>
  </li>

  <li className='sidebar-li'>
  <li class={`nav-item navbar-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="questionsets" ? 'sidebar-color':''}`}>
  <img src={require('../images/background.png')} className={value==='questionsets' ? '':'d-none'} style={{width:'100%',position:'absolute',top:'-30px',zIndex:1}} alt="" />
    <Link to='/question_sets' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-folder2-open w-25 ${value==="questionsets"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} viewBox="0 0 16 16">
  <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7z"/>
</svg>
    <span className={`${open ? 'w-75 d-inline' : 'd-none'} ${value==="questionsets"  ? 'text-dark nav-subtext' : 'sidebar-text'}`} style={{fontSize:'14px'}}>Question Sets</span></Link>
  </li>
  </li>

</ul>
      </div>
      <div className="" style={{width:'20px',borderRadius:'60px 0px 0px 60px',backgroundColor:'#F2F2F2'}}>

      </div>
    </div>
  )
}

export default Sidebar