import React from 'react'
import Main_navbar from './Main_navbar'
import Main_footer from './Main_footer'
import Backtotop from './Backtotop'
import axios from 'axios'

const About_us = () => {

  return (
    <div className='animate__animated animate__fadeIn' style={{minHeight:'100vh'}}>
        <Main_navbar value={"about"}/>

        <div className="main-container2 pt-4">
        <div className="m-0 row align-items-center container mx-auto py-5">
                <div className="col-md-6 mb-4 d-flex align-items-center justify-content-center">
                    <div className='pe-4'>
                    <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Where Curiosity Meets </p>
                    <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Expertise</p>
                    {/* <img src={require('../images/Line.png')} alt="" /> */}
                    <p className='text-secondary mt-4'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    
                    <div className='mt-4 pe-md-0'>
                        <button className='btn text-white px-5 py-2 fw-medium' style={{backgroundColor:'#185CFF',fontSize:'14px'}}>Learn more</button>
                       
                    </div>
                    </div>
                </div>
                <div className="col-md-6 mb-4 ps-md-0">
                    <img src={require('../images/about-us/01_img_10.png')} style={{width:'100%'}} alt="" />
                </div>

                <div className="col-md-6 mt-5">
                    <img src={require('../images/about-us/02_img_11.png')} style={{width:'100%'}} alt="" />
                </div>
                <div className="col-md-6 mt-5 d-flex align-items-center justify-content-center">
                    <div className='ps-4'>
                    <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Your Destination for</p>
                    <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Learning and Growth</p>
                    {/* <img src={require('../images/Line.png')} alt="" /> */}
                    <p className='text-secondary mt-4'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    
                    <div className='mt-4'>
                        <button className='btn text-white px-5 py-2 fw-medium' style={{backgroundColor:'#185CFF',fontSize:'14px'}}>Learn more</button>
                       
                    </div>
                    </div>
                </div>
        </div>

        {/* Container 2 */}
        <div className='py-5'>
            <div className="container m-0 mx-auto align-items-center row">
                <div className="col-md-6 ps-md-0">
                    <img src={require('../images/about-us/img_15.png')} style={{width:'100%',height:'425px'}} alt="" />
                </div>

                <div className="col-md-6 ps-md-4">
                    <div>
                        <p className='fw-medium fs-4 m-0'>The benefits of Ensome</p>
                        {/* <img src={require('../images/Line.png')} className='ps-2' alt="" /> */}

                        <div className='mt-4'>
                            <p className='d-flex align-items-center m-0'>
                                <img src={require('../images/Vector.png')} width={30} alt="" /> 
                                <span className='fw-medium ms-2'>Expert Answers</span></p>
                            <p className='text-secondary mt-2' style={{fontSize:'14px'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores. Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto.</p>
                        </div>

                        <div className='mt-3'>
                            <p className='d-flex align-items-center m-0'>
                                <img src={require('../images/Vector (1).png')} width={30} alt="" /> 
                                <span className='fw-medium ms-2'>Transforming Growth</span></p>
                            <p className='text-secondary mt-2' style={{fontSize:'14px'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores. Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto.</p>
                        </div>

                        <div className='mt-3'>
                            <p className='d-flex align-items-center m-0'>
                                <img src={require('../images/Vector (2).png')} width={26} alt="" /> 
                                <span className='fw-medium ms-2'>Inspiring Learning</span></p>
                            <p className='text-secondary mt-2' style={{fontSize:'14px'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores. Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Conatiner 3 */}
        <div className='py-5' style={{backgroundColor:'#F1F6FA'}}>
            <div className="container">
            <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>What our Student’s say </p>
            {/* <img src={require('../images/Line.png')} alt="" /> */}
            </div>
            
            <div className="row m-0 mt-4 container mx-auto">
                <div className="col-md-6 p-4 ps-md-0">
                    <div className='bg-white rounded p-4 shadow' style={{height:'300px'}}>
                        <div className='d-flex align-items-center'>
                        <img src={require('../images/001_img_person_1.png')} alt="" />
                        <div className='ms-3'>
                            <p className='fw-medium m-0' style={{fontSize:'14px'}}>Madhavan</p>
                            <p className='m-0 text-secondary mt-1' style={{fontSize:'13px'}}>Student of ICSE</p>
                        </div>
                        </div>
                        <p style={{color:'#9497A1',height:'140px'}} className='mt-4'>“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum.” </p>
                    </div>
                </div>

                <div className="col-md-6 p-4 pe-md-0">
                    <div className='bg-white rounded p-4 shadow' style={{height:'300px'}}>
                        <div className='d-flex align-items-center'>
                        <img src={require('../images/001_img_person_1.png')} alt="" />
                        <div className='ms-3'>
                            <p className='fw-medium m-0' style={{fontSize:'14px'}}>Jerold</p>
                            <p className='m-0 text-secondary mt-1' style={{fontSize:'13px'}}>Student of ICSE</p>
                        </div>
                        </div>
                        <p style={{color:'#9497A1',height:'140px'}} className='mt-4'>“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum.” </p>
                    </div>
                </div>
            </div>
        </div>

        {/* Container 4 */}
        <div className='py-5' style={{backgroundColor:'#ffff'}}>
            <div className='container'>
            <p className='fw-medium m-0' style={{letterSpacing:'1px',fontSize:'35px'}}>Our Blogs</p>
            {/* <img src={require('../images/Line.png')} alt="" /> */}
            </div>
            <div className="row m-0 mt-4 container mx-auto">
            
            <div className="col-md-4 p-4">
                <div class="card p-3">
  <img src={require('../images/img_8.png')} height={200} class="card-img-top" alt="..."/>
  <div class="card-body p-0 pt-3">
    <p className='text-secondary m-0' style={{fontSize:'13px'}}>29 JUN 2024</p>
    <h6 class="card-title mt-3 fw-medium">6 Strategies to Find Your Conference Keynote and Other Speakers</h6>
    
    <p className='m-0 fw-medium mt-3 d-flex align-items-center' style={{fontSize:'13px',color:'#185CFF',cursor:'pointer'}}><span className='me-1'>Read more</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></p>
  </div>
</div>
                </div>

                <div className="col-md-4 p-4">
                <div class="card p-3">
                <img src={require('../images/img_8.png')} height={200} class="card-img-top" alt="..."/>
  <div class="card-body p-0 pt-3">
    <p className='text-secondary m-0' style={{fontSize:'13px'}}>29 JUN 2024</p>
    <h6 class="card-title mt-3 fw-medium">6 Strategies to Find Your Conference Keynote and Other Speakers</h6>
    
    <p className='m-0 fw-medium mt-3 d-flex align-items-center' style={{fontSize:'13px',color:'#185CFF',cursor:'pointer'}}><span className='me-1'>Read more</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></p>
  </div>
</div>
                </div>

                <div className="col-md-4 p-4">
                <div class="card p-3">
                <img src={require('../images/img_8.png')} height={200} class="card-img-top" alt="..."/>
  <div class="card-body p-0 pt-3">
    <p className='text-secondary m-0' style={{fontSize:'13px'}}>29 JUN 2024</p>
    <h6 class="card-title mt-3 fw-medium">6 Strategies to Find Your Conference Keynote and Other Speakers</h6>
    
    <p className='m-0 fw-medium mt-3 d-flex align-items-center' style={{fontSize:'13px',color:'#185CFF',cursor:'pointer'}}><span className='me-1'>Read more</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></p>
  </div>
</div>
                </div>
            </div>

            {/* <div className='text-center mt-2'>
                <button className='btn rounded-pill px-4 py-2 fw-medium' style={{border:'1px solid #3D37F1',color:'#3D37F1'}}>Load More</button>
            </div> */}
        </div>

        {/* Container 5 */}
        <div style={{backgroundColor:'#F1F6FA',paddingTop:'70px',paddingBottom:'70px'}} className=''>
            <div className="row container m-0 mx-auto">
                <div className="col-md-5 d-flex flex-column justify-content-between">
                    <div>
                    <p className='fw-bold fs-3 m-0'>Contact Information</p>
                    {/* <img src={require('../images/Line.png')} alt="" /> */}
                    <p className='text-secondary mt-3' style={{fontSize:'14px'}}>Fill up the form and our Team will get back to you with 25 hours.</p>
                    </div>
                
                <div className='mt-5'>
                    <p className='mt-1 d-flex align-items-center' style={{fontSize:'14px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#185CFF" class="bi bi-envelope-fill" viewBox="0 0 16 16">
  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
</svg> <span className='ms-3'>madhvaan1312000@gmail.com</span></p>

<p className='mt-1 d-flex align-items-center' style={{fontSize:'14px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#185CFF" class="bi bi-telephone-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg><span className='ms-3'>8989898989</span></p>

<p className='mt-1 d-flex align-items-center' style={{fontSize:'14px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#185cff" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
</svg><span className='ms-3'>Bangalore</span></p>
                </div>
                </div>

                <div className="col-md-7">
                    <div className='p-2 px-4 mx-auto row m-0'>
                       <div className="col-md-6">
                        <div className=''>
                            <label htmlFor="" className='text-secondary' style={{fontSize:'14px'}}>Name</label>
                            <div className='border-bottom border-secondary'>
                                <input type="text" className='form-control border-0 shadow-none outline-0 bg-transparent' name="" id="" />
                            </div>
                        </div>
                       </div>

                       <div className="col-md-6">
                        <div className=''>
                            <label htmlFor="" className='text-secondary' style={{fontSize:'14px'}}>Email</label>
                            <div className='border-bottom border-secondary'>
                                <input type="text" className='form-control border-0 shadow-none outline-0 bg-transparent' name="" id="" />
                            </div>
                        </div>
                       </div>

                        <div className='mt-4 pt-3'>
                            <label htmlFor="" className='text-secondary' style={{fontSize:'14px'}}>Theme</label>
                            <div className='border-bottom border-secondary'>
                                <input type="text" className='form-control border-0 shadow-none outline-0 bg-transparent' name="" id="" />
                            </div>
                        </div>

                        <div className='mt-4 pt-3'>
                            <label htmlFor="" className='text-secondary' style={{fontSize:'14px'}}>Message</label>
                            <div className='border-bottom border-secondary'>
                                <input type="text" className='form-control border-0 shadow-none outline-0 bg-transparent' name="" id="" />
                            </div>
                        </div>

                        <div className='mt-4 pt-2 text-end'>
                            <button className='btn text-white px-5 py-2' style={{backgroundColor:'#185CFF',fontSize:'13px'}}>Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Main_footer/>
        <Backtotop/>
    </div>
  )
}

export default About_us