import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ipaddress } from '../App'
import { Modal } from 'react-bootstrap'
import Svg1 from '../Svgs/Svg1'
import { ThreeDots } from 'react-loader-spinner'

const Loginpage = () => {
    const navigate=useNavigate()

    const[email,setemail]=useState('')
    const[password,setpassword]=useState('')

    const[load,setload]=useState(false)
    const[type,settype]=useState(false)

    const login=()=>{
      setload(true)
      setTimeout(() => {
        send()
      }, 1000);
    }

    const send=()=>{
      if(value==='customer'){
        axios.post(`${ipaddress}/student/details/`,{
          email:email,
          password:password
        })
        .then((r)=>{
          console.log("Successs",r.data)
          sessionStorage.setItem('user',JSON.stringify(r.data))
          navigate('/all-tests')
          setload(false)
        })
        .catch((err)=>{
          console.log("Err",err)
          setload(false)
          toast.error('Invalid Credentials',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
          })
        })
      }
      if(value==='client'){
        axios.post(`${ipaddress}/admin-login/`,{
          username:email,
          password:password
        })
        .then((r)=>{
          console.log("Successs",r.data)
          sessionStorage.setItem('user',JSON.stringify(r.data))
          navigate('/admin_dashboard')
          setload(false)
        })
        .catch((err)=>{
          console.log("Err",err)
          setload(false)
          toast.error('Invalid Credentials',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
          })
        })
      }
    }

    const[value,setValue]=useState('customer')

    const[show2,setshow2]=useState(false)
    const[show3,setshow3]=useState(false)
    const[new_password,setnew_password]=useState('')
    const[pass_otp,setpass_otp]=useState('')
    const[load2,setload2]=useState(false)

    const forgot_password=()=>{
      setload2(true)
      axios.post(`${ipaddress}/password-reset/`,{
        email:email
      })
      .then((r)=>{
        toast.success('OTP sent to Mail',{
          autoClose:2000,
          theme:'colored',
          position:'top-center'
        })
        setload2(false)
        setshow2(false)
        setshow3(true)
      })
      .catch((err)=>{
        toast.warn('Email does not exist',{
          autoClose:2000,
          theme:'colored',
          position:'top-center'
        })
        setload2(false)
      })
    }

    const update_password=()=>{
      setload2(true)
      axios.post(`${ipaddress}/verify-otp/`,{
        email:email,
        otp:pass_otp,
        new_password:new_password
      })
      .then((r)=>{
        setload2(false)
        toast.success('Password updated!',{
          autoClose:1000,
          theme:'colored',
          position:'top-center'
        })
        setemail('')
        setshow3(false)
        setTimeout(() => {
          navigate('/loginpage')
        }, 2000);
      })
      .catch((err)=>{
        setload2(false)
        toast.error('Invalid OTP!',{
          autoClose:1000,
          theme:'colored',
          position:'top-center'
        })
      })
    }


  return (
    <div className='animate__animated animate__fadeIn'>
        <div className="m-0 row" style={{height:'100vh'}}>
            <div className="main-div col-md-6 d-flex align-items-center justify-content-center">
                <div>
                    <p className='text-white fw-bolder m-0 text-center' style={{fontSize:'40px'}}>Merida Tech</p>
                    <p className='text-white' style={{fontSize:'17px'}}>The most popular peer to peer leading at SEA</p>
                   
                </div>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className='login'>
                <img src={require('../images/SLA_logo_Blue 1.png')} className='d-block mx-auto mb-4' alt="" />
                <p className='text-center fw-medium'>Login into your account</p>
                </div>
                

                <div className='login'>
                    <div className='w-100'>
                  <label htmlFor="" className='my-3 signup-label'>Email ID</label>
                    <div style={{height:'60px'}} class="input-group px-3 rounded-pill border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="gray" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg></span>
  <input type="text" value={email} onChange={(e)=>{
    setemail(e.target.value)
  }} class="form-control signup-inputs shadow-none bg-transparent border-0" placeholder="Enter your email" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
                    </div>

                    <div className='w-100 mt-2'>
                  <label htmlFor="" className='my-3 signup-label'>Password</label>
                    <div style={{height:'60px'}} class="input-group mb-3 px-3 rounded-pill border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><Svg1/></span>
  <input type={type ? 'text':'password'} value={password} onChange={(e)=>{
    setpassword(e.target.value)
  }} class="form-control signup-inputs shadow-none bg-transparent border-0" placeholder="Enter your password" aria-label="Username" aria-describedby="basic-addon1"/>
  <span onClick={()=>{
    settype(!type)
  }} style={{cursor:'pointer'}} class="input-group-text bg-transparent border-0" id="basic-addon2"><Svg1/></span>
</div>
                    </div>

                    <p className='text-end mt-3 pe-2 text-decoration-underline' style={{cursor:'pointer',color:'#1E2772'}} onClick={()=>setshow2(true)}>Forgot Password ?</p>


                    <div className='pt-1'>
                        <button className='btn rounded text-white py-3 mt-4 w-100 fw-medium d-flex justify-content-center align-items-center' onClick={()=>{
                            // navigate('/mainpage')
                            login()
                        }} style={{backgroundColor:'#0575E6'}}>
                          <span className={`${load ? '':'d-none'}`}><ThreeDots
  visible={true}
  height="24"
  width="50"
  color="#ffff"
  radius="9"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  /></span>
  <span className={`${load ? 'd-none':''}`}>Login Now</span>

                        </button>
                    </div>
                    <p className='my-4 d-flex justify-content-between align-items-center'><img src={require('../images/Line 1.png')} alt="" />
                    <span className='text-secondary'>OR</span>
                    <img src={require('../images/Line 1.png')} alt="" /></p>

                    <button onClick={()=>{
                      navigate('/signuppage')
                    }} className='btn fw-medium w-100 py-3' style={{color:'#007AFF',border:'2px solid #007AFF',height:'57px'}}>
                      Signup Now
                    </button>
                </div>
            </div>
        </div>

{/* Forgot Password Modal */}
<Modal show={show2}>
        <Modal.Body className='bg-secondary-subtle rounded'>
          <div>
            <label htmlFor="">Enter your Email</label>
            <input type="email" value={email} onChange={(e)=>{
              setemail(e.target.value)
            }} className='form-control py-3 bg-light shadow-none mt-2' style={{border:'2px solid white'}} />

            <div className='mt-3 text-end'>
              <button className='btn text-white px-4 btn-sm py-2' style={{backgroundColor:'#0575E6',borderRadius:'10px'}} onClick={()=>{
                forgot_password()
              }}><span class={`spinner-grow spinner-grow-sm ${load2 ? '':'d-none'}`} aria-hidden="true"></span>
              <span className={load2 ? 'ms-2':'d-none'} role="status">Processing...</span>
              <span className={load2 ? 'd-none':''}>Get OTP</span></button>
              <button className='py-2 btn btn-danger px-4 btn-sm ms-3' style={{borderRadius:'10px'}} onClick={()=>{
                setshow2(false)
              }}>Close</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

{/* Forgot Password Modal */}
<Modal show={show3}>
        <Modal.Body className='bg-secondary-subtle rounded'>
          <div>
            <label htmlFor="">Enter your OTP</label>
            <input type="number" value={pass_otp} onChange={(e)=>{
              setpass_otp(e.target.value)
            }} className='form-control py-3 bg-light shadow-none mt-2' style={{border:'2px solid white'}} />

<label htmlFor="" className='mt-4'>Enter your New Password</label>
<div class="input-group mb-3 px-3 inputs bg-light mt-2 rounded py-2 " style={{border:'2px solid white'}}>
  <input type={type ? 'text':'password'} value={new_password} onChange={(e)=>{
    setnew_password(e.target.value)
  }} class="form-control py-2 shadow-none bg-transparent border-0" aria-label="Username" aria-describedby="basic-addon1"/>
  <span onClick={()=>{
    settype(!type)
  }} style={{cursor:'pointer'}} class="input-group-text bg-transparent border-0" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="gray" class="bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
</svg></span>
</div>

            <div className='mt-3 text-end'>
              <button className='py-2 btn text-white px-4 btn-sm' style={{backgroundColor:'#0575E6',borderRadius:'10px'}} onClick={()=>{
                update_password()
              }}><span class={`spinner-grow spinner-grow-sm ${load2 ? '':'d-none'}`} aria-hidden="true"></span>
              <span className={load2 ? 'ms-2':'d-none'} role="status">Submitting...</span>
              <span className={load2 ? 'd-none':''}>Submit</span></button>
              <button className='py-2 btn btn-danger px-4 btn-sm ms-3' style={{borderRadius:'10px'}} onClick={()=>{
                setshow3(false)
              }}>Close</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Loginpage