import React from 'react'

const Svg2 = () => {
  return (
    <div>
      <svg width="20" height="20" viewBox="0 0 360 512" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M167.519 505.32C170.301 509.493 174.985 512 180 512C185.015 512 189.699 509.494 192.481 505.32C227.98 452.073 280.267 386.312 316.703 319.431C345.837 265.955 360 220.346 360 180C360 80.748 279.252 0 180 0C80.748 0 0 80.748 0 180C0 220.346 14.163 265.955 43.297 319.431C79.706 386.261 132.093 452.183 167.519 505.32ZM180 30C262.71 30 330 97.29 330 180C330 215.204 317.033 256.118 290.359 305.079C258.952 362.728 214.134 421.207 180 470.369C145.871 421.214 101.05 362.731 69.641 305.079C42.967 256.118 30 215.204 30 180C30 97.29 97.29 30 180 30Z" fill="#808080"/>
<path d="M180 270C229.626 270 270 229.626 270 180C270 130.374 229.626 90 180 90C130.374 90 90 130.374 90 180C90 229.626 130.374 270 180 270ZM180 120C213.084 120 240 146.916 240 180C240 213.084 213.084 240 180 240C146.916 240 120 213.084 120 180C120 146.916 146.916 120 180 120Z" fill="#808080"/>
</svg>

    </div>
  )
}

export default Svg2
