import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress } from '../App'
import { useNavigate } from 'react-router-dom'

const Questionsets = () => {
    const[questionsets,setquestionsets]=useState([])

    useEffect(()=>{
    axios.get(`${ipaddress}/question-sets/`)
        .then((r)=>{
            console.log("question sets",r.data);
            setquestionsets(r.data)
        })
    },[])

    const navigate=useNavigate()
    


  return (
    <div className='d-flex'>
      <Sidebar activevalue={"questionsets"}/>
      <div className="w-100 animate__animated animate__fadeIn">
        <Navbar/>
        <div className="main-container py-4 pe-md-4 ps-md-2">
            <h5 className='mb-4'>Question Sets</h5>

            <div className="table-responsive">
            <table class="table shadow-sm">
  <thead>
  <tr>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>SI.No</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Module Name</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Basic Count</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Intermediate Count</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Advanced Count</p></th>
    </tr>
  </thead>
  <tbody>
    {questionsets.map((x,index)=>{
        return(
            <tr className={index%2==0 ? 'table-light':''}>
      <th scope="row" className='border-0'><p className='text-center m-0 py-2'>{index+1}</p></th>
      <td className='border-0'><p className='text-center m-0 py-2'>{x.module_name}</p></td>
      <td className='border-0'><p className='text-center m-0 py-2'>{x.basic_count}</p></td>
      <td className='border-0'><p className='text-center m-0 py-2'>{x.intermediate_count}</p></td>
      <td className='border-0'><p className='text-center m-0 py-2'>{x.advanced_count}</p></td>
    </tr>
        )
    })}
  </tbody>
</table>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Questionsets
