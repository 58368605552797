import React from 'react'
import { useNavigate } from 'react-router-dom'

const Navbar_offcanvas = () => {
    const navigate=useNavigate()

  return (
    <div>
      <div class="offcanvas offcanvas1 offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
        <img src={require('../images/Logo_Blue 1 (1).png')} width={110} alt="" />
        <h5 class="offcanvas-title" id="offcanvasRightLabel"></h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div className='mt-4'>
        <p onClick={()=>{
            navigate('/all-tests')
        }} data-bs-dismiss="offcanvas" className='d-flex align-items-center justify-content-between nav-link mb-5'><span className='fw-medium'>Home</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></p>
<p onClick={()=>{
            navigate('/projects')
        }} data-bs-dismiss="offcanvas" className='d-flex align-items-center justify-content-between nav-link mb-5'><span className='fw-medium'>Projects</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></p>
<p onClick={()=>{
            navigate('')
        }} data-bs-dismiss="offcanvas" className='d-flex align-items-center justify-content-between nav-link mb-5'><span className='fw-medium'>Contact Us</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></p>
<p  data-bs-dismiss="offcanvas" className='d-flex align-items-center justify-content-between nav-link mb-5'><span className='fw-medium'>Logout</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></p>
    </div>
  </div>
</div>
    </div>
  )
}

export default Navbar_offcanvas
