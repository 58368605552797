import React from 'react'
import Loginpage from './Loginpage'

const Protect2 = ({Child}) => {

    const user=JSON.parse(sessionStorage.getItem('user'))

    const verify=()=>{
        if(user==null){
            return false
        }
        else{
            return true
        }
    }

  return (
    <div>
        {verify() ? <Child/> : <Loginpage/>}
    </div>
  )
}

export default Protect2