import React, { useContext, useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { useNavigate, useParams } from 'react-router-dom'
import Backtotop from './Backtotop'
import axios from 'axios'
import { ipaddress } from '../App'
import Preloader from './Preloader'
import Upcoming_events from '../Events/Upcoming-events'
import { Context } from '../Context_provider'

const Main_page = () => {

    const {event_length}=useContext(Context)

    const navigate=useNavigate()
    const{id}=useParams()
    const {cat_name}=useParams()

    const[technical_skills,settechnical_skills]=useState([])
    const[count,setcount]=useState(0)
    const[loading,setloading]=useState(false)

    useEffect(()=>{
        setloading(true)
        axios.get(`${ipaddress}/mainstacks/${id}/skills/`)
        .then((r)=>{
            console.log("Technical skills",r.data);
            settechnical_skills(r.data)
            setloading(false)
        })
    },[count])

    const search_skill=(value)=>{
        if(value.length>0){
            axios.get(`${ipaddress}/mainstacks/${id}/skills/?name=${value}`)
            .then((r)=>{
                console.log("Technical skills",r.data);
                settechnical_skills(r.data)
            })
        }
        else{
            setcount(count+1)
        }
    }

return (
    <div className='animate__animated animate__fadeIn row m-0 sub-categories-div' style={{minHeight:'100vh'}}>
        <div className={` ${event_length ? 'col-lg-9':'col-12'}`}>
        <div className=''>
        <Navbar value={"home"}/>
       {loading ? (<Preloader/>):(
         <div className='pt-4 container'>
         <p className='m-0 fw-medium text-center cat_text'>Discover Your True Potential with Our</p>
         <p className='fw-medium text-center cat_text'>Skill Assessment Tests</p>
         {/* <div className='text-center mb-4'>
             <button className='btn btn-primary px-3 py-2' style={{fontSize:'14px'}}>Project Categories</button>
         </div> */}
         <div className='row m-0 mt-4 pt-3'>
         <div className="col-lg-9 mx-auto px-md-0">
             <div class="input-group mb-3 rounded border border-dark py-2 rounded-pill mb-4">
<span class="input-group-text border-0 bg-transparent ps-3" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg></span>
<input type="text" onChange={(e)=>{
 search_skill(e.target.value)
}} class="form-control border-0 bg-transparent shadow-none" placeholder="Search Technical Skills..." aria-label="Username" aria-describedby="basic-addon1"/>
</div>
             </div>
            {technical_skills.map((x,index)=>{
             return(
                 <div key={index} className={`col-md-6 col-lg-4 mb-4 mt-4 px-md-3`}>
                 <div onClick={()=>{
                          const noSpacesString = `${x.name}`.replace(/\s+/g, ''); 
                          const val=noSpacesString.toLowerCase()
                         navigate(`/${cat_name}/${val}/${x.tech_id}`)
                     }} className='subcategory-subdiv d-flex align-items-center px-3 py-3 py-lg-0' style={{position:'relative',cursor:'pointer'}}>
                 <img src={x.image} width={100} height={100} className='' alt="" />
                     {/* <img src={require('../images/subCategories/codingicon862 1.png')} width={140} height={110} className='' alt="" /> */}
                     <div className='ms-3'>
                         <p className='text-white fw-medium m-0' style={{fontSize:'20px'}}>{x.name}</p>
                         <p className='text-white mt-2 m-0' style={{fontSize:'13px'}}>{x.desc}</p>
                     </div>
                     <svg style={{position:'absolute',right:'16px',bottom:'0px',cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="24" height="30" fill="white" class="bi bi-arrow-right" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg>
                 </div>
             </div>
             )
            })}
         </div>
     </div>
       )}
    </div>
        </div>

        <div className={`col-lg-3 ${event_length ? '':'d-none'}`}>
            <Upcoming_events/>
        </div>

        <Footer/>
        {/* <Backtotop/> */}
    </div>
  )
}

export default Main_page