import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const Homepage2 = () => {

    const[show,setshow]=useState(false)

  return (
    <div>
      {/* <div className='py-5' style={{backgroundColor:'#F1F6FA'}}>
            <p className='fw-medium m-0 text-center container mx-auto' style={{letterSpacing:'1px',fontSize:'35px'}}>What we are </p>
            <p className='fw-medium m-0 text-center container mx-auto' style={{letterSpacing:'1px',fontSize:'35px'}}>going to provide?</p>
            <p className='mt-3 text-secondary text-center container mx-auto px-2 px-md-5'>Doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>

            <div className="row m-0 mt-5 container mx-auto">
                <div className="col-md-4 p-4">
                    <div className='bg-white rounded p-4 shadow' style={{height:'350px'}}>
                        <img src={require('../images/Vector.png')} alt="" />

                        <p className='fw-medium fs-5 mt-4' style={{height:'60px'}}>Register for Upcoming SAT</p>
                        <p style={{color:'#9497A1'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.</p>
                    </div>
                </div>

                <div className="col-md-4 p-4">
                    <div className='bg-white rounded p-4 shadow' style={{height:'350px'}}>
                        <img src={require('../images/Vector (1).png')} alt="" />

                        <p className='fw-medium fs-5 mt-4' style={{height:'60px'}}>2024 AP Exam scores will be available starting July 8</p>
                        <p style={{color:'#9497A1'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.</p>
                    </div>
                </div>

                <div className="col-md-4 p-4">
                    <div className='bg-white rounded p-4 shadow' style={{height:'350px'}}>
                        <img src={require('../images/Vector (2).png')} alt="" />

                        <p className='fw-medium fs-5 mt-4' style={{height:'60px'}}>Match with Scholarships and Colleges</p>
                        <p style={{color:'#9497A1'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.</p>
                    </div>
                </div>
            </div>
        </div> */}

        <div className='py-5' style={{backgroundColor:'#F1F6FA'}}>
            <p className='fw-medium m-0 text-center container mx-auto' style={{letterSpacing:'1px',fontSize:'35px'}}>What we are </p>
            <p className='fw-medium m-0 text-center container mx-auto' style={{letterSpacing:'1px',fontSize:'35px'}}>going to provide?</p>
            <p className='mt-3 text-secondary text-center container mx-auto px-2 px-md-5'>Doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>

            <div className="row m-0 mt-5 container mx-auto">
                <div className="col-md-4 p-4">
                    <div className='bg-white rounded p-4 shadow' style={{height:'350px'}}>
                        <img src={require('../images/Vector.png')} alt="" />

                        <p className='fw-medium fs-5 mt-4' style={{height:'60px'}}>Register for Upcoming SAT</p>
                        <p style={{color:'#9497A1'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.</p>

                        {/* <button onClick={()=>{
                            setshow(true)
                        }} className='btn btn-primary px-4 mt-3' style={{fontSize:'14px'}}>Subscribe Now</button> */}
                    </div>
                </div>

                <div className="col-md-4 p-4">
                    <div className='bg-white rounded p-4 shadow' style={{height:'350px'}}>
                        <img src={require('../images/Vector (1).png')} alt="" />

                        <p className='fw-medium fs-5 mt-4' style={{height:'60px'}}>2024 AP Exam scores will be available starting July 8</p>
                        <p style={{color:'#9497A1'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.</p>
                    </div>
                </div>

                <div className="col-md-4 p-4">
                    <div className='bg-white rounded p-4 shadow' style={{height:'350px'}}>
                        <img src={require('../images/Vector (2).png')} alt="" />

                        <p className='fw-medium fs-5 mt-4' style={{height:'60px'}}>Match with Scholarships and Colleges</p>
                        <p style={{color:'#9497A1'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.</p>
                    </div>
                </div>
            </div>
        </div>

        <Modal show={show} size='md' onHide={()=>{
            setshow(false)
        }}>
        <Modal.Header className='d-flex align-items-center justify-content-between'>
          <Modal.Title className='fs-6 text-primary'>Register Now!</Modal.Title>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
        </Modal.Header>
        <Modal.Body>
            <div className='row m-0'>
                <div className="col-12 mb-3">
                    <label htmlFor="" className='signup-labels'>Name</label>
                    <input type="text" className='form-control shadow-none py-2 mt-2' />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="" className='signup-labels'>Email</label>
                    <input type="email" className='form-control shadow-none py-2 mt-2' />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="" className='signup-labels'>Contact Number</label>
                    <input type="text" className='form-control shadow-none py-2 mt-2' />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="" className='signup-labels'>Address</label>
                    <input type="text" className='form-control shadow-none py-2 mt-2' />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="" className='signup-labels'>Pincode</label>
                    <input type="text" className='form-control shadow-none py-2 mt-2' />
                </div>

                <div className='col-12'>
                    <button className='btn btn-secondary rounded-pill w-100 py-2 my-3'>Register</button>
                    <p className='text-center' style={{fontSize:'14px'}}>Already have account ? <span className='text-primary'>Login now</span></p>
                </div>
            </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Homepage2
