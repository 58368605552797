import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Backtotop from './Backtotop'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress } from '../App'
import { Modal } from 'react-bootstrap'
import Preloader from './Preloader'

const Take_test = ({set_score}) => {

    const {id}=useParams()

    const navigate=useNavigate()

    const[count,setcount]=useState(0)

    const[all_questions,setall_questions]=useState([])

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[test_data,settest_data]=useState({})

    const[q_id,setq_id]=useState(1)
    const [overlayVisible, setOverlayVisible] = useState(false);

    useEffect(()=>{

        if(document.documentElement.requestFullscreen){
            document.documentElement.requestFullscreen()
        }

        document.addEventListener('contextmenu',(event)=>{
            event.preventDefault()
        })

        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                event.preventDefault();
            // alert("You exited full-screen mode! The test will be submitted.");
            //  submit_test()   
            }
        };
        window.addEventListener('keydown', handleEscKey);

        // Blur event, when window loses focus it will trigger the function
        const handleWindowBlur = () => {
            // alert("You switched away from the test! The test will be submitted.");
            setOverlayVisible(true)
            // submit_test();
        };
        window.addEventListener('blur', handleWindowBlur);

        fetch_all_questions()
    
          document.addEventListener('visibilitychange', handleVisibilityChange);
          document.addEventListener('fullscreenchange', handleFullscreenChange)
      
          // Clean up event listeners
          return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            sessionStorage.removeItem('test_in_progress');
          };
    },[])

    const[test_id,settest_id]=useState()

    useEffect(()=>{
        fetch_test_data()
    },[id])

    const fetch_test_data=()=>{
        axios.post(`${ipaddress}/tests/`,{
            student:user.id,
            module:id,
            mcq:true
           })
            .then((r)=>{
                // console.log("Tests",r.data)
                settest_id(r.data.test_id)
                setTimeLeft(r.data.time_limit)
            })
    
    }

    const handleFullscreenChange = () => {
        if (!document.fullscreenElement) {
            // The user exited full-screen mode
            // alert("You exited full-screen mode! The test will be submitted.");
            setOverlayVisible(true)
            submit_test();
        }
    };

   // Handle beforeunload event
   const handleBeforeUnload = (event) => {
    // Set a flag in the session storage
    sessionStorage.setItem('test_in_progress', 'true');
    submit_test()
};

  // Handle visibilitychange event
  const handleVisibilityChange = () => {
    // if (document.hidden) {
    //     // submit_test()
    //     // alert('HIIII')
    //     fetch_test_data()
    //     setTimeout(() => {
    //         submit_test()
    //     }, 2000);
    // }
};

    const[question,setquestion]=useState({})

    useEffect(()=>{
        if(all_questions.length>0){
            const data=all_questions.filter(x=>x.question_no===q_id)
        // console.log("Question data",data);
        setquestion(data[0])
        }
    },[q_id,all_questions])

    const[loading2,setloading2]=useState(false)

    const fetch_all_questions=()=>{
        setloading2(true)
        axios.get(`${ipaddress}/modules/${id}/mcq-questions/?student=${user.id}`)
        .then((r)=>{
            // console.log("All questions",r.data);
            setall_questions(r.data)
            setloading2(false)
        })
    }

    // const select_question=(id)=>{
    //     const data=all_questions.filter(x=>x.id===id)
    //     console.log("Question",data);
    //     setquestion(data[0])
    // }

    const [currentQuestion, setCurrentQuestion] = useState(0);

    const submit_question=(option)=>{
        axios.post(`${ipaddress}/TempMCQAnswerCreateView/`,{
            test_id:test_id,
            question_id:question.id,
            selected_option_id:option,
            student_id:user.id
        })

        .then((r)=>{
            
            fetch_all_questions()
            console.log("TRIGGERED")
        })

    }

    useEffect(()=>{
        filter_questions()
    },[all_questions])

    const mark_question=()=>{
        axios.post(`${ipaddress}/TempMCQAnswerCreateView/`,{
            test_id:test_id,
            question_id:question.id,
            student_id:user.id,
            mark:true
        })

        .then((r)=>{
            fetch_all_questions()
            console.log("TRIGGERED")
        })

    }

    const [timeLeft, setTimeLeft] = useState(); // Total seconds (2 hours)

    const[state,setstate]=useState(false)
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
      if(timeLeft==0){
        setstate(true)

        submit_test()
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  const[loading,setloading]=useState(false)

  const submit_test=()=>{
    setloading(true)

    console.log("TEST ID",test_id)
    axios.post(`${ipaddress}/submit-mcq-test/`,{
        test_id:test_id,
        student_id:user.id
       })
       .then((r)=>{
       navigate('/success',{replace:true})
    //    console.log("Successfully submitted",r.data);
       set_score(r.data)
       setloading(false)
       if(document.fullscreenElement){
        document.exitFullscreen()
       }
       })
       .catch((err)=>{
       console.log("Submission error",err);
       console.log({
        'test_id':test_id,
        'student_id':user.id
       });
       
       setloading(false)

       })
  }

   const handleOverlayAction = (action) => {
        if (action === 'submit') {
            submit_test();
        } else if (action === 'return') {
            // Return to full-screen mode
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
                    .catch(err => {
                        console.error("Failed to re-enter full-screen mode:", err);
                    });
            }
        }
        setOverlayVisible(false); // Hide the overlay after action
    };

    const[show,setshow]=useState(false)

    const[unanswered_questions,setunanswered_questions]=useState([])
    const[answered_questions,setanswered_questions]=useState([])

    const filter_questions=()=>{
        const data=all_questions.filter(x=>x.attended==false)
        const data2=all_questions.filter(x=>x.attended==true)
        console.log("Answered questions",data2.length+1);
        setunanswered_questions(data)
        setanswered_questions(data2)

    }

    const[progress,setprogress]=useState()
    useEffect(()=>{
        if (currentQuestion < all_questions.length) {
            setCurrentQuestion(answered_questions.length+1);
          }
    },[answered_questions])

    useEffect(()=>{
        if(currentQuestion>0){
           const data=answered_questions.find(x=>x.id===q_id)
           console.log(data)
           if(!data){
            setprogress((currentQuestion / all_questions.length) * 100);
           }
        }
    },[currentQuestion])


    useEffect(()=>{
        const questionContainer=document.querySelector('question-container')
        const allquestionsContainer=document.querySelector('all-question-container')

        const handleScroll=(e)=>{
            e.target.style.overflowY='auto'
        }

        if (questionContainer) {
            questionContainer.addEventListener('scroll', handleScroll);
          }
        
          if (allquestionsContainer) {
            allquestionsContainer.addEventListener('scroll', handleScroll);
          }

          return ()=>{
            
        if (questionContainer) {
            questionContainer.removeEventListener('scroll', handleScroll);
          }
        
          if (allquestionsContainer) {
            allquestionsContainer.removeEventListener('scroll', handleScroll);
          }
          }
    },[])

return (
    <div className='test-page' style={{minHeight:'100vh'}}>
        {/* <Navbar/> */}

       {loading2 ? (<Preloader/>):(
         <div className='pt-5 container pb-3'>
         <div className='row m-0 align-items-center'>
                 <div className='col-md-3'>
                     <p className='fw-bold m-0' style={{color:'#F5C345'}}>MCQ Test : <span className='fw-bold text-white'>{test_data.module_name}</span></p>
                     <p className='fw-medium mt-2' style={{color:'#F5C345'}}>Time Left : <span className='fw-medium text-white'> {hours.toString().padStart(2, '0')} : {minutes.toString().padStart(2, '0')} : {seconds.toString().padStart(2, '0')}</span></p>
                 </div>

                 <div className="col-md-6">
                    {/* <p className='text-center m-0 text-white fw-medium mb-2' style={{fontSize:'13px'}}>Progress</p> */}
                    <div className="progress" role="progressbar" aria-label="Question progress" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
        <div className="progress-bar text-bg-warning" style={{ width: `${progress}%` }}>
          {/* {currentQuestion} */}
        </div>
      </div>
      <p className='text-start m-0 text-white fw-medium mb-2' style={{fontSize:'13px'}}>Answered questions : {answered_questions.length}</p>

                 </div>

                 <div className='col-md-3 d-flex align-items-center justify-content-end'>
                     <div className='me-3'>
                     <p className='fw-bold m-0 text-end text-white'>{user.username}</p>
                     <p className='fw-medium text-end mt-1 text-white m-0' style={{fontSize:'13px'}}>Profile</p>
                     </div>
                     <img src={require('../images/Clip path group.png')} alt="" />
                 </div>
             </div>
 
            <div className=''>
 
             <div className='row m-0 mt-4'>
                 <div className="col-md-8 ps-md-0 mb-5 mb-md-0">
                     <div className='question-container options-div shadow-sm' style={{borderRadius:'15px',height:'70vh',overflowY
                         :'auto'
                     }}>
                        <div className='question-div'>
                        <p className='fw-medium text-white fs-5 p-2 p-md-4'><span className='me-2'>{q_id}.</span>{question.question_text && question.question_text}</p>
                         {question.imageforquestion && (
                             <img src={question.imageforquestion} style={{width:'50%'}} alt="" />
                         )}
                        </div>
 
                         <div className="p-2 p-md-4">
                         <div className="row m-0 pt-4">
                             {question && question.options && question.options.map((x,index)=>{
                                 return(
                                     <div className="col-12 ps-md-0 mb-3">
                                 <p onClick={()=>{
                                     submit_question(x.id)
                                 }} className={`p-2 py-3 px-4 fw-medium ${x.selected ? 'selected-options':'options'}`} style={{borderRadius:'10px',cursor:'pointer'}}>{index+1}] <span className='ms-3 fw-medium'>{x.option_text}</span></p>
 
 {x.imageforoption && (
                             <img src={x.imageforoption} className='my-2' style={{width:'50%'}} alt="" />
                         )}
                             </div>
                                 )
                             })}
                         </div>
                         </div>
                     </div>
                 </div>
 
                 <div className="col-md-4 pe-md-0 ps-md-4">
                 <div className='all-question-container shadow-sm p-3 all-questions' style={{borderRadius:'15px 15px 0px 0px',height:'63vh',overflowY:'auto'}}>
                 <p className='fw-medium border-bottom pb-2' style={{color:'#ffff',fontSize:'15px'}}>All Questions ({all_questions && all_questions.length})</p>
                 <div className="row m-0 mt-4">
                    {all_questions.map((x,index)=>{
                     return(
                         <div className="col-2 ps-0">
                         <p onClick={()=>{
                             setq_id(x.question_no)
                             // select_question(x.id)
                         }} className={`rounded d-flex align-items-center justify-content-center ${q_id===x.question_no ? 'fw-bold':''} ${x.ishold ? 'marked-questions':''} ${x.attended && !x.ishold ? 'selected-questions text-white':'bg-white'}`} style={{height:'34px',width:'34px',fontSize:'14px',cursor:'pointer'}}>{index+1}</p>
                     </div>
                     )
                    })}
                 </div>
                 </div>
                 <div className='py-2 text-center shadow-sm all-questions' style={{height:'7vh',borderRadius:'0px 0px 15px 15px'}}>
                     {/* <button className='btn btn-primary px-4' style={{fontSize:'13px'}} onClick={mark_question}>Mark as Review</button> */}
                 </div>
                 </div>
             </div>
 
             <div className='d-flex align-items-center justify-content-between mt-4 mb-5 mb-md-0 pt-2'>
                 <button className={`btn btn-light   fw-medium px-4 py-2`} disabled={q_id>1 ? false:true} style={{color:'#185CFF',fontSize:'15px'}} onClick={()=>{
                     setq_id(q_id-1)
                 }}>Prev</button>
                 <button className='btn btn-white fw-medium px-4 py-2' style={{color:'#ffff',fontSize:'15px',border:'1px solid #ffff'}} onClick={()=>{
                     setshow(true)
                 }}>{loading ? 'Submitting...' : 'Submit Test'}</button>
                 <button className='btn btn-light fw-medium px-4 py-2' disabled={q_id>=all_questions.length ? true:false} style={{color:'#185CFF',fontSize:'15px'}} onClick={()=>{
                     setq_id(q_id+1)
                 }}>Next</button>
             </div>
            </div>
 
            <div className='success d-none'>
             <div className='text-center'>
                 {/* <img src={require('../images/undraw_winners_ao2o.png')} alt="" /> */}
             </div>
             <p className='text-info fw-medium fs-4 text-center mt-4 m-0'>Congratulations <span className='text-dark ms-1'>Madhavan!</span></p>
            <p className='text-center mt-2 fw-medium'>You have passed the test!</p>
 
            <div className='text-center'>
             <button className='btn text-white rounded-pill px-4' style={{fontSize:'13px',backgroundColor:'#185CFF'}}>Back to courses</button>
            </div>
            </div>
         </div>
       )}

        {/* CUSTOM POPUP */}
        <div className={overlayVisible ? 'overlay' : 'd-none'} style={{ 
                    position: 'fixed', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100vh', 
                    backgroundColor: 'rgba(0,0,0,0.6)', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    zIndex: 10 
                }}>
                    <div className='text-center' style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
                        <p className='fw-bold fs-5'>You switched away from the test!</p>
                        <p>The test will be submitted now.</p>
                        <button className='btn btn-primary mx-2' onClick={() => handleOverlayAction('submit')}>OK</button>
                        <button className='btn btn-secondary mx-2' onClick={() => handleOverlayAction('return')}>Back</button>
                    </div>
                </div>

        {/* <Footer/> */}
        <Backtotop/>

{/* Layout */}
<div className={state ? 'animate__animated animate__fadeIn':'d-none'} style={{backgroundColor:'rgba(0,0,0,0.6)',width:'100%',height:'100vh',position:'fixed',top:0,left:0,zIndex:6}}>
<div className="row justify-content-center">
    <div className="bg-white col-md-6 p-2 mt-3" style={{borderRadius:'10px'}}>
        <div className='py-4 mb-2'>
           <p className='fw-bold fs-5 text-center m-0'>Exam Time Duration Completed!!!</p>
        </div>
    </div>
</div>
</div>

{/* Unanswered questions */}
<Modal show={show} centered size='md' onHide={()=>{
    setshow(false)
}}>
        <Modal.Body style={{backgroundColor:'#ffff',borderRadius:'10px'}}>
            <div className='row m-0 py-3'>
            <img src={require('../images/Artboard 1.png')} className='d-block mx-auto' style={{width:'50%'}} alt="" />
                <p className='text-dark fw-medium text-center m-0 mt-4'>Are you sure about submission of test?</p>
            {/* <div className="col-12">
                <div className='border border-secondary-subtle p-3' style={{backgroundColor:'#E4F6FF',borderRadius:'15px'}}>
                <p className='fw-medium border-bottom pb-2' style={{color:'#636363',fontSize:'15px'}}>Answered Questions ({answered_questions && answered_questions.length})</p>
                <div className="row m-0 mt-4">
                   {answered_questions.map((x,index)=>{
                    return(
                        <div className="col-2 col-md-1 ps-0">
                        <p className={`rounded d-flex align-items-center justify-content-center ${x.attended ? 'selected-questions':'questions'}`} style={{height:'34px',width:'34px',fontSize:'14px'}}>{index+1}</p>
                    </div>
                    )
                   })}
                </div>
                </div>
                </div>
            <div className="col-12 mt-4">
                <div className='border border-secondary-subtle p-3' style={{backgroundColor:'#E4F6FF',borderRadius:'15px'}}>
                <p className='fw-medium border-bottom pb-2' style={{color:'#636363',fontSize:'15px'}}>Unanswered Questions ({unanswered_questions && unanswered_questions.length})</p>
                <div className="row m-0 mt-4">
                   {unanswered_questions.map((x,index)=>{
                    return(
                        <div className="col-2 col-md-1 ps-0">
                        <p className={`rounded d-flex align-items-center justify-content-center ${x.attended ? 'selected-questions':'questions'}`} style={{height:'34px',width:'34px',fontSize:'14px'}}>{index+1}</p>
                    </div>
                    )
                   })}
                </div>
                </div>
                </div> */}

               <div className='mt-4 col-lg-6 mx-auto'>
                   
                    <button className='btn btn-primary w-100' onClick={submit_test}>
                    <span class={`spinner-border spinner-border-sm ${loading ? '':'d-none'}`} aria-hidden="true"></span>
                    <span className={loading ? 'ms-2':'d-none'} role="status" style={{fontSize:'14px'}}>Submitting...</span>
                    <span className={loading ? 'd-none':''} style={{fontSize:'14px'}}>Submit Test</span>
                    </button>

                    <button className='btn border-0 fw-medium d-block mx-auto' style={{fontSize:'14px'}} onClick={()=>{
                        setshow(false)
                    }}>Back</button>
                </div>
            </div>
        </Modal.Body>
      
      </Modal>
    </div>
  )
}

export default Take_test