import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress } from '../App'
import { useNavigate, useParams } from 'react-router-dom'
import Backtotop from '../Pages/Backtotop'

const View_modules = () => {

    const {id}=useParams()

    const[modules,setmodules]=useState([])

    useEffect(()=>{
        axios.get(`${ipaddress}/technical-skills/${id}/modules/`)
        .then((r)=>{
            console.log("Modules",r.data);
            setmodules(r.data)
        })
    },[])

    const navigate=useNavigate()

  return (
    <div className='d-flex'>
      <Sidebar activevalue={"skills"}/>
      <div className="w-100 animate__animated animate__fadeIn">
        <Navbar/>
        <div className="main-container py-4 pe-md-4 ps-md-2">
            <h5 className='mb-4 d-flex align-items-center'><svg style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/technical_skills`)
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg> <span className='ms-2'>Modules</span></h5>

            <div className="table-responsive">
            <table class="table shadow-sm">
  <thead>
    <tr>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>SI.No</p></th>
      <th scope="col" className='border-0'></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Modules Name</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2'>Time Limit</p></th>
    </tr>
  </thead>
  <tbody>
    {modules.map((x,index)=>{
        return(
            <tr className={index%2==0 ? 'table-light':''}>
      <th scope="row" className='border-0'><p className='text-center m-0 py-2'>{index+1}</p></th>
      <td className='border-0'><div className='d-flex align-items-center justify-content-center'>
        <img src={x.image} className='rounded-circle' width={40} height={40} alt="" /></div></td>
      <td className='border-0'><p className='text-center m-0 text-primary fw-medium py-2' style={{cursor:'pointer'}} onClick={()=>{
        navigate(`/questions/${id}/${x.module_id}`)
      }}>{x.name}</p></td>
      <td className='border-0'><p className='text-center m-0 py-2'>{x.time_limit}</p></td>
    </tr>
        )
    })}
  </tbody>
</table>
            </div>
        </div>
      </div>

      <Backtotop/>
    </div>
  )
}

export default View_modules
